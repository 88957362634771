/* eslint-disable filenames/match-exported */
// import { genericAPIActionTypes, genericAPIActionCreator } from '../../util';
// import * as api from '../../api';

const ACTIONS = {
  ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  CLOSE_MAP_CHAPTER: 'Chapter:Map:CloseChapter',
  HIDE_DRAWER: 'Drawer:Hide',
  SET_CHAPTER_FILTER: 'Chapter:Filter:Set',
  SET_CURRENT_MAP_CHAPTER: 'Chapter:Map:SetCurrentChapter',
  SET_ROLE_FILTER: 'Role:Filter:Set',
  SHOW_DRAWER: 'Drawer:Show',
  TOGGLE_DRAWER: 'Drawer:Toggle',
  TITLE_SET: 'Title:Set',
};

export const showDrawer = () => ({
  type: ACTIONS.SHOW_DRAWER,
});

export const hideDrawer = () => ({
  type: ACTIONS.HIDE_DRAWER,
});

export const toggleDrawer = () => ({
  type: ACTIONS.TOGGLE_DRAWER,
});

export const setCurrentMapChapter = (chapter) => ({
  type: ACTIONS.SET_CURRENT_MAP_CHAPTER,
  chapter,
});

export const closeMapChapter = (chapter) => ({
  type: ACTIONS.CLOSE_MAP_CHAPTER,
  chapter,
});

export const setChapterFilter = (chapter) => ({
  type: ACTIONS.SET_CHAPTER_FILTER,
  chapter,
});

export const setRoleFilter = (role) => ({
  type: ACTIONS.SET_ROLE_FILTER,
  role,
});

export const setTitle = (title) => ({
  type: ACTIONS.TITLE_SET,
  title,
});

export default ACTIONS;
