import styled from 'styled-components';

const ChaptersMapStyles = styled.div`
  i {
    left: 50%;
    position: absolute;
    bottom: 40px;
    margin-left: -12px;
    color: ${(props) => props.theme.color.light};
  }
`;

export const ChapterModal = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -150px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  .close {
    float: right;
    width: 30px;
    height: 30px;
    background: #000;
    color: #fff;
    text-align: 0;
    line-height: 1.1em;
    border-radius: 100%;
    margin: -30px;
    cursor: pointer;
    &:hover {
      background: ${(props) => props.theme.color.primary};
      text-decoration: none;
    }
  }
  .img {
    border-radius: 100%;
    margin-top: -80px;
    border: solid 5px ${(props) => props.theme.color.secondary};
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
  }
  .button {
    position: relative;
    bottom: -30px;
  }
  .stats {
    padding: 20px 0;
    font-size: 0.8em;
    margin-bottom: -20px;
    h3 {
      text-transform: none;
    }
    h4 {
      color: ${(props) => props.theme.color.primary};
      font-size: 0.8em;
      line-height: 1em;
    }
    h1 {
      font-size: 40px;
      color: ${(props) => props.theme.color.secondary};
    }
  }
  @media screen and (max-width: 460px) {
    transform: scale(0.7);
    margin-top: -200px;
  }
`;

export default ChaptersMapStyles;
