import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  FormSelect,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const PROPRIETARY_OPTIONS = [
  ['yes', 'Yes'],
  ['no', 'No'],
];

const CompetitionFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Competition &amp; Defensibility</h4>

      <div className="col-sm-12">
        <Field
          component={TextField}
          label="Who are your 3 major competitors? (Max 75 characters)"
          name="profile['data']['competitors']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['company_information']"
          component={TextField}
          label="Tell us about your companies technology (Max 300 characters)"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['proprietary']"
          component={FormSelect}
          options={PROPRIETARY_OPTIONS}
          label="Has your company built unique or proprietary technology?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

CompetitionFields.propTypes = propTypes;
CompetitionFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CompetitionFields);
