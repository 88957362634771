import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import slugify from 'slugify';
import ProblemHackingStyles from './problem_hacking_styles';
import theme from '../../../common/theme';
import Button from '../../../common/components/button';
import FlamelinkImage from '../../../util/flamelink_image';
import Hero from '../hero';
import Snippet from '../snippet';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.instanceOf(Map),
};

const defaultProps = {
  page: new Map(),
};

const ProblemHackingView = ({ page }) => (
  <ProblemHackingStyles>
    <PageMetaHead page={page} />
    { page.get('hero') && page.get('hero') !== '<p></p>\n' && (
      <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container body">

      <section style={{ marginTop: '50px' }}>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        {page.get('caseStudyLink') &&
        <Button link={page.get('caseStudyLink')} color="#fff" background={theme.color.primary} target="_blank">View Case Study</Button>}
      </section>

      <hr />

      <section>
        <div className="row cohort-role">
          <div className="col-md-6">
            <Snippet slug="problem-hacking-founder-apply" />
          </div>
          <div className="col-md-6">
            <Snippet slug="problem-hacking-coach-apply" />
          </div>
        </div>

        <div className="row cohort-disclaimer">
          <div className="col-md-12">
            This is a double-opt in matching process. We’ll contact you in advance to confirm proposed pairings.
          </div>
        </div>
      </section>

      <hr />

      <section>
        <div className="row">
          <div className="col-md-6">
            <div dangerouslySetInnerHTML={{ __html: page.get('bodyLeft') }} />
          </div>
          <div className="col-md-6">
            <div dangerouslySetInnerHTML={{ __html: page.get('bodyRight') }} />
          </div>
        </div>
      </section>

    </div>

    <div className="container">

      { page.get('cohortApplications') && (
        <section className="cohort-applications">
          <h3>
            <span className="primary">
              APPLICATIONS ARE CURRENTLY OPEN
              <br />
            </span>
            FOR THE FOLLOWING COHORTS
          </h3>
          <div className="row">
            { page.get('cohortApplications').map((item) => (
              <div className="col-sm-4 center" key={slugify(item.get('title'))}>
                {item.get('title')}
                <br />
                <small>
                  {item.get('subtitle')}
                </small>
                <FlamelinkImage id={item.get('image').first()} width="100%" height="100%" />
                {item.get('buttonLabel') && <Button link={item.get('buttonLink')} target="_blank">{item.get('buttonLabel')}</Button>}
              </div>
            ))}
          </div>
        </section>
      )}

    </div>


  </ProblemHackingStyles>
);

ProblemHackingView.propTypes = propTypes;
ProblemHackingView.defaultProps = defaultProps;

export default ProblemHackingView;
