import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import slugify from 'slugify';
import EventStyles from './event_styles';
import Hero from '../hero';
import EventMap from '../event_map';
import theme from '../../../common/theme';
import Button from '../../../common/components/button';
import MetaHead from '../../../util/meta_head';
import FlamelinkImage from '../../../util/flamelink_image';
import ContentAttr from '../../../util/content_attr';
import SocialSharing from '../social_sharing';

const propTypes = {
  event: PropTypes.instanceOf(Map),
};

const defaultProps = {
  event: new Map(),
};

const EventView = ({ event }) => (
  <EventStyles>
    <MetaHead title={`Event / ${event.get('title')}`} description={event.get('metaDescription')} keywords={event.get('metaKeywords')} />
    <Hero background={event.get('heroBackground') && event.get('heroBackground').first()}>
      <section>
        {event.get('chapter') && (
          <h3>
            <ContentAttr schema="chapters" id={event.get('chapter')} attr="title" />
          </h3>
        )}
        <h1>{event.get('title')}</h1>
        {event.get('ticketLink') && (
          <div style={{ textAlign: 'center', paddingTop: '40px' }}>
            <Button color={theme.color.primary} background="#fff" link={event.get('ticketLink')}>
              Apply to Pitch
            </Button>
          </div>
        )}
      </section>
    </Hero>

    <section className="info-collage">
      {event.get('infoCollage') &&
        event.get('infoCollage').map((item) => (
          <div key={slugify(item.get('title'))}>
            <div className="text">
              <div className="text-inner">
                <div style={{ marginBottom: '6px' }}>
                  <FlamelinkImage style={{ backgroundColor: '#ffffff', backgroundSize: 'contain' }} id={item.get('icon').first()} width="50px" height="50px" />
                </div>
                {item.get('title')}
                <div dangerouslySetInnerHTML={{ __html: item.get('description') }} />
              </div>
            </div>
            <FlamelinkImage id={item.get('image').first()} />
          </div>
        ))}
    </section>

    <section className="share">
      <div>
        <SocialSharing />
      </div>
      <p>Share this event</p>
    </section>

    {event.get('body') && event.get('body') !== '<p></p>\n' ? (
      <div className="container body">
        <section>
          <div dangerouslySetInnerHTML={{ __html: event.get('body') }} />
        </section>
      </div>
    ) : null}

    {event.get('eventBrite') && (
      <section className="eventbrite">
        <iframe
          title={event.get('title')}
          src={event.get('eventBrite')}
          frameBorder="0"
          height="500"
          width="100%"
          vspace="0"
          hspace="0"
          marginHeight="5"
          marginWidth="5"
          scrolling="auto"
        />
      </section>
    )}

    <div className="container">
      {event.get('participants') && (
        <section className="participants">
          {event.get('participants').map((item) => (
            <div key={slugify(item.get('title'))}>
              <h2 className="primary">{item.get('title')}</h2>
              <div className="row">
                {item.get('participants') &&
                  item.get('participants').map((participant) => (
                    <div
                      key={slugify(participant.get('name'))}
                      className={`col-xs-6 col-sm-4 ${item.get('participants').size === 5 ? ' col-md-5ths' : ' col-md-3'}`}
                    >
                      <div className="participant">
                        <FlamelinkImage id={participant.get('image').first()} width="100%" height="100%" />
                        <div className="text">
                          <span>
                            <strong>{participant.get('name')}</strong>
                            <p>{participant.get('role')}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </section>
      )}

      {event.get('locationMap') && (
        <section className="location-map row">
          <div className="text col-md-4">
            <h3>{event.get('locationMap').get('locationTitle')}</h3>
            <div dangerouslySetInnerHTML={{ __html: event.get('locationMap').get('locationAddress') }} />
          </div>
          <div className="col-md-8">
            <EventMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-YEXwa7_vy0QG1T7q_ps0wO6bBrJ3nlI"
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '400px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              lat={event.get('locationMap').get('latitude')}
              lng={event.get('locationMap').get('longitude')}
            />
          </div>
        </section>
      )}
    </div>
  </EventStyles>
);

EventView.propTypes = propTypes;
EventView.defaultProps = defaultProps;

export default EventView;
