// eslint-disable-next-line no-unused-vars
import React from 'react';
import { connect } from 'react-redux';
import { getContentBySlug, getContentByID } from '../reducer';

const ContentAttr = connect((state, { slug, id, schema }) => ({
  item: id ? getContentByID(state, schema, id) : getContentBySlug(state, schema, slug),
}))(({ schema, slug, id, item, attr }) => <>{(item && item.get(attr)) || `${schema}/${slug || id}/${attr}`}</>);

export default ContentAttr;
