import React from 'react';
import PropTypes from 'prop-types';
import HeroStyles from './hero_styles';
import { FadeIn, FadeInUp } from '../../../util/animation';
import FlamelinkImage from '../../../util/flamelink_image';

const propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.number,
};

const defaultProps = {
  background: undefined,
};

const HeroView = ({ children, background }) => (
  <HeroStyles>
    <FadeIn initialPose="hidden" pose="visible">
      {background && (
        <div className="background">
          <FlamelinkImage id={background} width="100%" height="100%" />
        </div>
      )}
      <div className="container content">
        <FadeInUp initialPose="hidden" pose="visible">
          {children}
        </FadeInUp>
      </div>
    </FadeIn>
  </HeroStyles>
);

HeroView.propTypes = propTypes;
HeroView.defaultProps = defaultProps;

export default HeroView;
