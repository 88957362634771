import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import SponsorsStyles from './sponsors_styles';
import FlamelinkImage from '../../../util/flamelink_image';

const propTypes = {
  sponsors: PropTypes.instanceOf(Map),
  chapter: PropTypes.string.isRequired,
};

const defaultProps = {
  sponsors: new Map(),
};

const SponsorsView = ({ sponsors, chapter }) => (
  <SponsorsStyles>
    <h2 className="primary">Sponsors</h2>
    <div className="row">
      {sponsors
        .valueSeq()
        .filter((sponsor) => sponsor.get('chapter') === chapter && !sponsor.get('program-partner'))
        .map((sponsor) => (
          <div key={sponsor.get('slug')} className="col-md-4 center">
            <div className="sponsor-title">{sponsor.get('title')}</div>
            <div className="sponsor" style={{ marginBottom: '20px' }}>
              <FlamelinkImage style={{ backgroundSize: 'contain', backgroundColor: '#fff' }} id={sponsor.get('image').first()} width="150px" height="150px" />
            </div>
          </div>
        ))}
    </div>
    <div className="row sponsors-program-partners" style={{ textAlign: 'center' }}>
      Program Partners
    </div>
    <div className="row">
      {sponsors
        .valueSeq()
        .filter((sponsor) => sponsor.get('chapter') === chapter && sponsor.get('program-partner'))
        .map((sponsor) => (
          <div key={sponsor.get('slug')} className="col-md-2 center">
            <div className="sponsor" style={{ marginBottom: '20px' }}>
              <FlamelinkImage style={{ backgroundSize: 'contain', backgroundColor: '#fff' }} id={sponsor.get('image').first()} width="100%" height="60px" />
            </div>
          </div>
        ))}
    </div>
  </SponsorsStyles>
);

SponsorsView.propTypes = propTypes;
SponsorsView.defaultProps = defaultProps;

export default SponsorsView;
