import { fromJS, OrderedSet, Record } from 'immutable';
import { combineReducers } from 'redux-immutable';

const EMPTY_MAP = fromJS({});
const EMPTY_ORDERED_SET = new OrderedSet([]);
const DEFAULT_STATE = Record({
  byId: EMPTY_MAP,
  allIds: EMPTY_ORDERED_SET,
  loadingStatus: false,
  lastUpdated: undefined,
});

const getOne = (state, id) => state.getIn(['byId', id]);
const getList = (state, list = state.get('allIds')) => list.map((id) => getOne(state, id));
const getLastUpdated = (state) => state.get('lastUpdated');
const getLoadingStatus = (state) => state.get('loadingStatus');

export default function CRUDResourcefulReducer(resourceName, model, { fetch, create, update, remove, list } = {}) {
  const Fetch = fromJS(fetch || [`GET_${resourceName.toUpperCase()}`]);
  const Create = fromJS(create || [`CREATE_${resourceName.toUpperCase()}`]);
  const Delete = fromJS(remove || [`DELETE_${resourceName.toUpperCase()}`]);
  const Update = fromJS(update || [`UPDATE_${resourceName.toUpperCase()}`]);
  const List = fromJS(list || [`ALL_${resourceName.toUpperCase()}`]);
  const ResourceModel = model;

  const asModels = (resources) => fromJS(resources).map((resource) => new ResourceModel(resource));

  const byId = (state = EMPTY_MAP, action) => {
    if (action.response && action.response.entities && action.response.entities[resourceName]) {
      return state.merge(asModels(action.response.entities[resourceName]));
    }
    return state;
  };

  const allIds = (state = EMPTY_ORDERED_SET, action) => {
    if (action.type === Create.get('success')) return new OrderedSet(state).concat(new OrderedSet(action.response.result));
    if (action.type === Fetch.get('success')) return new OrderedSet(action.response.result);
    if (action.type === Delete.get('success')) return new OrderedSet(state).filter((val) => val !== action.response.result);
    // if (action.type === Update.get('success')) return new OrderedSet(action.response.result);
    return state;
  };

  const loadingStatus = (state = false, action) => {
    switch (action.type) {
      case Create.get('request'):
      case Fetch.get('request'):
      case Delete.get('request'):
      case Update.get('request'):
        return true;
      case Create.get('success'):
      case Create.get('failure'):
      case Fetch.get('success'):
      case Fetch.get('failure'):
      case Delete.get('success'):
      case Delete.get('failure'):
      case Update.get('success'):
      case Update.get('failure'):
        return false;
      default:
        return state;
    }
  };

  const lastUpdated = (state = false, action) => {
    if (List.includes(action.type)) return Date.now();
    return state;
  };

  const reducer = combineReducers(
    {
      byId,
      allIds,
      lastUpdated,
      loadingStatus,
    },
    DEFAULT_STATE,
  );
  reducer.selectors = {
    getOne,
    getList,
    getLastUpdated,
    getLoadingStatus,
  };
  return reducer;
}
