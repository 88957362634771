import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import MediaStyles from './media_styles';
import Hero from '../hero';
import Button from '../../../common/components/button';
import FlamelinkImage from '../../../util/flamelink_image';
import theme from '../../../common/theme';

const propTypes = {
  page: PropTypes.shape({}),
};

const defaultProps = {
  page: new Map(),
};

const MediaView = ({ page }) => (
  <MediaStyles>
    {page.get('hero') && (
      <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      <section>
        <h2 className="primary">Pressroom</h2>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        <div style={{ height: 30 }} />
        {/* eslint-disable-next-line max-len */}
        <Button link="https://firebasestorage.googleapis.com/v0/b/vinetta-project.appspot.com/o/flamelink%2Fmedia%2F1551551218590_VinettaProjectPartnerships.pdf?alt=media">
          Download Press Kit
        </Button>
        <div style={{ display: 'inline-block', width: 20 }} />
        <Button link="mailto:info@vinettaproject.com" background={theme.color.primary} color="#fff">
          Email Us
        </Button>
      </section>
    </div>
    <div className="primaryBg">
      <div className="container">
        <section>
          <h2>We go beyond funding to launch leaders who deliver unapologetic growth.</h2>
          <div style={{ height: 30 }} />
          <Button link="/about" color="#fff">
            About Vinetta
          </Button>
        </section>
      </div>
    </div>
    <div className="container">
      <section>
        <h2 className="primary">As seen in</h2>
        <div className="row">
          {page.get('gallery') &&
            page.get('gallery').map((image) => (
              <div className="col-md-2" key={image.get('id')}>
                {image.get('url') ? (
                  <a href={image.get('url')} target="_blank" rel="noopener noreferrer">
                    <FlamelinkImage
                      style={{ backgroundSize: 'contain', backgroundColor: '#fff' }}
                      id={image.get('image').first()}
                      width="100%"
                      height="200px"
                    />
                  </a>
                ) : (
                  <FlamelinkImage style={{ backgroundSize: 'contain', backgroundColor: '#fff' }} id={image.get('image').first()} width="100%" height="200px" />
                )}
              </div>
            ))}
        </div>
      </section>
    </div>
  </MediaStyles>
);

MediaView.propTypes = propTypes;
MediaView.defaultProps = defaultProps;

export default MediaView;
