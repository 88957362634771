import styled from 'styled-components';

const HeroStyles = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.color.primary};
  margin-top: -20px;
  height: calc(100vh - 70px);
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  section {
    color: #fff;
    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }
  }
  i {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    margin: auto;
    color: ${(props) => props.theme.color.light};
  }
  .content {
    position: relative;
    z-index: 3;
    max-width: 800px;
    padding: 30px;
    p {
      @media screen and (max-width: 600px) {
        margin-top: 0;
        font-size: 0.8em;
      }
    }
  }
  .background {
    position: relative;
    z-index: 1;
  }
  .background::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.color.primary};
    opacity: 0.9;
  }
  .background,
  .background .img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export default HeroStyles;
