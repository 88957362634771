import styled from 'styled-components';

const PartnershipStyles = styled.div`
  .page-body {
    h4 + p + img + p {
      display: inline-block;
      color: ${(props) => props.color || props.theme.color.primary};
      font-size: 90px;
      line-height: 90px;
      margin: 0 35px;
      font-weight: lighter;
      position: relative;
      top: 15px;
    }
  }
  .mentors {
    .mentor {
      cursor: pointer;
      width: 100%;
      padding-top: 100%;
      margin-top: 30px;
      height: 0;
      position: relative;
      overflow: hidden;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      > .img,
      > .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .text {
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 20px;
        font-size: 0.8em;
        color: #4a4a4a;
        background: ${(props) => props.color || props.theme.color.primary};
        strong {
          text-transform: uppercase;
        }
        p {
          font-size: 0.9em;
          margin: 0;
        }
      }
      &:hover {
        .text {
          display: flex;
        }
      }
    }
  }

  .partner-brands {
    .carousel-container {
      height: 350px;
      overflow: hidden;
      max-width: 1000px;
      margin: 0 auto;
      @media screen and (min-width: 550px) {
        padding: 0 14px;
      }
    }
    .brand-slide {
      height: 350px;
      overflow: hidden;
    }
    .carousel {
      position: relative;
      z-index: 0;
      @media screen and (min-width: 550px) {
        width: calc(100% - 60px);
        margin-left: 30px;
      }
    }
    .carousel__slider {
      z-index: 1;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100px;
        z-index: 2;
      }
      &::after {
        right: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
      &::before {
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
    .carousel__slider-tray-wrapper {
      width: 80%;
      margin-left: 10%;
      @media screen and (min-width: 768px) {
        width: 60%;
        margin-left: 20%;
      }
    }
    .carousel__back-button,
    .carousel__next-button {
      position: absolute;
      outline: none;
      z-index: 2;
      top: 135px;
      background-color: ${(props) => props.theme.color.primary};
      color: ${(props) => props.theme.color.light};
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: 100%;
      i {
        font-size: 36px;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
    .carousel__next-button {
      right: -24px;
    }
    .carousel__back-button {
      left: -24px;
    }
    .brand-slide {
      background: #f1f1f1;
      margin: 0 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 10% 0;
      text-align: center;
      .img {
        flex-shrink: 0;
        width: 110px;
        height: 110px;
        border: 6px solid ${(props) => props.theme.color.light};
        border-radius: 100%;
        margin-right: 20px;
      }
      p {
        font-size: 0.6em;
      }
      @media screen and (min-width: 550px) {
        flex-direction: row;
        margin: 0 10px;
        padding: 0 10%;
        text-align: left;
        .img {
          width: 148px;
          height: 148px;
        }
      }
    }
  }
  .program-eligibility {
    ul {
      list-style-type: none;
      padding: 0 0 20px;
      display: block;
      border-bottom: solid 1px ${(props) => props.theme.color.secondary};
    }
    li {
      display: inline;
      margin-right: 30px;
      font-size: 0.8em;
      &:hover {
        cursor: pointer;
      }
      &.react-tabs__tab--selected {
        color: ${(props) => props.theme.color.primary};
      }
    }
    &.program-eligibility-size-small li {
      margin-right: 15px;
      font-size: 0.65em;
    }
    .button {
      float: right;
    }
  }
  .footerText {
    p {
      font-size: 0.5em;
    }
  }
`;

export default PartnershipStyles;
