import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ThumbGridStyles from './thumb_grid_styles';
import Thumb from '../thumb';

const propTypes = {
  leaders: PropTypes.instanceOf(Map),
  filter: PropTypes.string.isRequired,
  roleFilter: PropTypes.string,
};

const defaultProps = {
  leaders: new Map(),
  roleFilter: undefined,
};

const ThumbGridView = ({ leaders, filter, roleFilter }) => (
  <ThumbGridStyles>
    <div className="row row-fluid">
      {leaders && leaders
        .valueSeq()
        .filter((leader) => !filter || leader.get('chapter') === filter)
        .filter((leader) => !roleFilter || leader.get('role') === roleFilter)
        .sortBy((leader) => leader.get('order'))
        .map((leader) => (
          <div key={leader.get('slug')} className="col-md-3">
            <Thumb leader={leader} />
          </div>
        ))}
    </div>
  </ThumbGridStyles>
);

ThumbGridView.propTypes = propTypes;
ThumbGridView.defaultProps = defaultProps;

export default ThumbGridView;
