/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Component } from 'react';
import { connect } from 'react-redux';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  pathname: state.getIn(['router', 'location', 'pathname']),
});

export default connect(mapStateToProps)(ScrollToTop);
