/* eslint-disable react/prop-types */
import React from 'react';
import StatisticsStyles from './statistics_styles';
import Variable from '../variable';

const StatisticsView = () => (
  <StatisticsStyles>
    <ul>
      <li>
        <div>
          <article>
            <h4 className="primary">Founders</h4>
            <h2 className="secondary">
              <Variable slug="founder-count" animate />
            </h2>
          </article>
        </div>
      </li>
      <li>
        <div>
          <article>
            <h4 className="primary">Investors</h4>
            <h2 className="secondary">
              <Variable slug="investor-count" animate />
            </h2>
          </article>
        </div>
      </li>
      <li>
        <div>
          <article>
            <h4 className="primary">Corporate Partners</h4>
            <h2 className="secondary">
              <Variable slug="corporate-partner-count" animate />
            </h2>
          </article>
        </div>
      </li>
      <li>
        <div>
          <article>
            <h4 className="secondary">
              Current
              <br />
              Cities
            </h4>
            <h2 className="secondary">
              <Variable slug="city-count" animate />
            </h2>
          </article>
        </div>
      </li>
      <li>
        <div>
          <article>
            <h4 className="secondary">
              Community
              <br />
              Members
            </h4>
            <h2 className="secondary">
              <Variable slug="member-count" animate />
            </h2>
          </article>
        </div>
      </li>
      <li>
        <div>
          <article>
            <h4 className="secondary">
              Capital
              <br />
              Raised
            </h4>
            <h2 className="secondary">
              <Variable slug="funds-raised" animate prefix="$" suffix="M" />
            </h2>
          </article>
        </div>
      </li>
    </ul>
  </StatisticsStyles>
);

export default StatisticsView;
