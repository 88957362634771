import styled from 'styled-components';

const SponsorsStyles = styled.div`
  .sponsor-title {
    margin-top: 30px;
  }
  .sponsor {
    padding: 40px 0;
    margin-top: 15px;
  }
  .sponsors-program-partners {
    margin: 30px 0 20px;
  }
`;

export default SponsorsStyles;
