import React from 'react';
import PropTypes from 'prop-types';
import FounderStyles from './founder_styles';
import theme from '../../../common/theme';
import Button from '../../../common/components/button';
import Testimonials from '../testimonials';
import Snippet from '../snippet';
import FlamelinkImage from '../../../util/flamelink_image';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.instanceOf(Map),
};

const defaultProps = {
  page: new Map(),
};

const FounderView = ({ page }) => (
  <FounderStyles>
    <PageMetaHead page={page} />
    <div className="container">
      <section>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        <Button link="https://app.vinettaproject.com/data_sets/fQhFe3gvwZCeYOYo" target="_blank">Join Vinetta</Button>
      </section>
    </div>
    <Testimonials category="founder" />
    <div className="container">
      <section>
        <div className="row">
          <div className="col-md-6">
            <iframe
              src={'https://player.vimeo.com/video/320995542?wmode=opaque&amp;api=1'}
              style={{ width: '100%', height: '240px' }}
              frameBorder="0"
              title="Vinetta - DC"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            />
          </div>
          <div className="col-md-6">
            <Snippet slug="founder-video" />
          </div>
        </div>
      </section>
      <hr />
      <section>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h2 className="primary">Who's In</h2>
        <Snippet slug="founder-whos-in" />

        <div className="row">
          {page.get('gallery') &&
            page.get('gallery').map((image, idx) => (
              <div className={`col-md-2 ${idx % 5 === 0 ? ' col-md-offset-1' : ''}`} key={image.get('id')}>
                <FlamelinkImage style={{ backgroundSize: 'contain', backgroundColor: '#fff' }} id={image.get('image').first()} width="100%" height="200px" />
              </div>
            ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button link="https://app.vinettaproject.com/data_sets/fQhFe3gvwZCeYOYo" background={theme.color.primary} width="15em" color="#fff" target="_blank">
          Apply Now
          </Button>
        </div>
      </section>
    </div>
  </FounderStyles>
);

FounderView.propTypes = propTypes;
FounderView.defaultProps = defaultProps;

export default FounderView;
