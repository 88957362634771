const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  color: {
    primary: '#FF7062',
    secondary: '#C3D2C7',
    light: '#FFF',
    dark: '#000',
    action: '#FF7062',
    actionHover: '#000',
    text: '#6D6D6D',
    disabled: '#CCCCCC',
    whiteOffset: '#EEEEEE',
    grayLighter: '#CCCCCC',
    error: '#EE0000',
  },
  font: {
    sansSerif: '"Source Sans Pro", sans-serif',
    serif: '"Source Serif Pro", serif',
    mono: '"Source Code Pro", monospace',
  },
  text: {
    fontSize: 24,
    lineHeight: 36,
  },
  button: {
    borderRadius: 3,
  },
  screen: {},
  section: {},
  padding: {
    small: 5,
    base: 10,
    large: 20,
  },
};

export default theme;
