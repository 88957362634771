import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import SnippetStyles from './snippet_styles';
import Button from '../../../common/components/button';
import { FadeIn } from '../../../util/animation';
import FlamelinkImage from '../../../util/flamelink_image';

const propTypes = {
  snippet: PropTypes.instanceOf(Map),
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  imageStyle: PropTypes.shape({}),
};

const defaultProps = {
  snippet: new Map(),
  imageWidth: '100%',
  imageHeight: undefined,
  imageStyle: null,
};

const SnippetView = ({ snippet, imageWidth, imageHeight, imageStyle }) => (
  <SnippetStyles>
    <FadeIn initialPose="hidden" pose="visible">
      {snippet.get('image') && <FlamelinkImage id={snippet.get('image').first()} width={imageWidth} height={imageHeight} style={imageStyle} />}
      <div className="snippet-text">
        {snippet.get('title') && <h5>{snippet.get('title')}</h5>}
        <small>
          <div dangerouslySetInnerHTML={{ __html: snippet.get('body') }} />
        </small>
        {snippet.get('linkText') && (
          <Button size="small" link={snippet.get('link')}>
            {snippet.get('linkText')}
          </Button>
        )}
      </div>
    </FadeIn>
  </SnippetStyles>
);

SnippetView.propTypes = propTypes;
SnippetView.defaultProps = defaultProps;

export default SnippetView;
