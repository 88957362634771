import React from 'react';
import PropTypes from 'prop-types';
import VimeoHeroStyles from './vimeo_hero_styles';
import { OpenUp } from '../../../util/animation';

const propTypes = {
  id: PropTypes.string.isRequired,
};

const defaultProps = {};

const VimeoHeroView = ({ id }) => (
  <VimeoHeroStyles>
    <OpenUp initialPose="hidden" pose="visible">
      <section style={{ padding: 0, background: '#000' }}>
        <div className="container">
          <iframe
            src={`https://player.vimeo.com/video/${id}?wmode=opaque&amp;api=1`}
            style={{ width: '100%', height: 'calc(50vw)', maxHeight: 1170 / 2 }}
            frameBorder="0"
            title="Vinetta"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
        </div>
      </section>
    </OpenUp>
  </VimeoHeroStyles>
);

VimeoHeroView.propTypes = propTypes;
VimeoHeroView.defaultProps = defaultProps;

export default VimeoHeroView;
