import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ChaptersStyles from './chapters_styles';
import Snippet from '../snippet';
import ChaptersMap from '../chapters_map';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.instanceOf(Map),
};

const defaultProps = {
  page: new Map(),
};

const ChaptersView = ({ page }) => (
  <ChaptersStyles>
    <PageMetaHead page={page} />
    <ChaptersMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-YEXwa7_vy0QG1T7q_ps0wO6bBrJ3nlI"
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: 'calc(100vh - 80px)', minHeight: '600px' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
    <div className="container">
      <section className="pillars">
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        <div style={{ height: 60 }} />
        <div className="row">
          <div className="col-md-6">
            <div className="pillar">
              <div className="icon">
                <img src="/images/icon-attend.png" alt="Icon" />
              </div>
              <h5>Attend</h5>
              <small>
                <Snippet slug="chapter-attend" />
              </small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pillar">
              <div className="icon">
                <img src="/images/icon-discover.png" alt="Icon" />
              </div>
              <h5>Discover</h5>
              <small>
                <Snippet slug="chapter-discover" />
              </small>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="pillar">
              <div className="icon">
                <img src="/images/icon-meet.png" alt="Icon" />
              </div>
              <h5>Meet</h5>
              <small>
                <Snippet slug="chapter-meet" />
              </small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pillar">
              <div className="icon">
                <img src="/images/icon-learn.png" alt="Icon" />
              </div>
              <h5>Learn</h5>
              <small>
                <Snippet slug="chapter-learn" />
              </small>
            </div>
          </div>
        </div>
      </section>
    </div>
  </ChaptersStyles>
);

ChaptersView.propTypes = propTypes;
ChaptersView.defaultProps = defaultProps;

export default ChaptersView;
