// Firebase App is always required and must be first
import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyBczstXmcjeUE-ns3MJwO9kKBGKu7PEXlg',
  authDomain: 'vinetta-project.firebaseapp.com',
  databaseURL: 'https://vinetta-project.firebaseio.com',
  projectId: 'vinetta-project',
  storageBucket: 'vinetta-project.appspot.com',
  messagingSenderId: '910747870347',
};

firebase.initializeApp(config);

export const db = firebase.database;

export default firebase;
