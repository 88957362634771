/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import * as moment from 'moment';
import { withSize } from 'react-sizeme';
import { Map } from 'immutable';
import theme from '../../../common/theme';
import EventsUpcomingStyles from './events_upcoming_styles';
import Button from '../../../common/components/button';
import ContentAttr from '../../../util/content_attr';
import FlamelinkImage from '../../../util/flamelink_image';

const propTypes = {
  events: PropTypes.instanceOf(Map),
};

const defaultProps = {
  events: new Map(),
};

const Event = ({ event }) => (
  <div key={event.get('slug')}>
    <div className="thumb">
      <div className="overlay">
        <div className="overlay-inner">
          {event.get('chapter') && (
            <h3 className="chapter">
              <ContentAttr schema="chapters" id={event.get('chapter')} attr="title" />
            </h3>
          )}
          <h2 className="event-name">{event.get('title')}</h2>
          <p>{moment(event.get('dateTime')).format('MMMM Do, YYYY')}</p>
          <div className="cta">
            {event.get('moreInfoOverrideLink') && (
              <Button link={event.get('moreInfoOverrideLink')} color={theme.color.primary} target="_blank">
              More Info
              </Button>
            )}
            {!event.get('moreInfoOverrideLink') && (
              <Button link={`/events/${event.get('slug')}`} color={theme.color.primary}>
              More Info
              </Button>
            )}
          </div>
        </div>
      </div>
      <FlamelinkImage id={event.get('image').first()} width="100%" height="200px" />
      <h5>
        {event.get('location')}
        <br />
        {moment(event.get('dateTime')).format('MMM Do')}
      </h5>
      <small>{event.get('blurb')}</small>
    </div>
  </div>
);

const sizeToSlideCount = (width) => {
  let val;
  if (width < 670) {
    val = 1;
  } else if (width < 970) {
    val = 2;
  } else {
    val = 3;
  }
  return val;
};

const EventsUpcomingView = ({ events, chapter, size }) => (
  <EventsUpcomingStyles>
    <h2 className="primary">Upcoming Events</h2>
    <div className="carousel-container">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={events.filter((event) => !chapter || event.get('chapter') === chapter.get('id')).valueSeq().size}
        visibleSlides={sizeToSlideCount(size.width)}
        step={sizeToSlideCount(size.width)}
        interval={6000}
      >
        {size.width > 640 && (
          <>
            <ButtonBack>
              <i className="material-icons">chevron_left</i>
            </ButtonBack>
            <ButtonNext>
              <i className="material-icons">chevron_right</i>
            </ButtonNext>
          </>
        )}
        <Slider>
          {/* eslint-disable-next-line max-len */}
          {events
            .valueSeq()
            .filter((event) => !chapter || event.get('chapter') === chapter.get('id'))
            .filter((event) => moment(event.get('dateTime')).isSameOrAfter(moment(), 'day'))
            .sortBy((event) => event.get('dateTime'))
            .map((event, index) => (
              <Slide key={index} index={index}>
                <Event event={event} />
              </Slide>
            ))}
        </Slider>
      </CarouselProvider>
    </div>
  </EventsUpcomingStyles>
);

EventsUpcomingView.propTypes = propTypes;
EventsUpcomingView.defaultProps = defaultProps;

export default withSize()(EventsUpcomingView);
