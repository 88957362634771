import styled from 'styled-components';

const SocialSharingStyles = styled.div`
  > button {
    border: 0;
    background: transparent;
    outline: none;
    svg {
      width: 36px;
      height: 36px;
      transition: transform 0.3s;
      path {
        transition: fill 0.3s;
      }
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
      svg path {
        fill: ${(props) => props.theme.color.dark};
      }
    }
  }
`;

export default SocialSharingStyles;
