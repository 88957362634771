import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import numeral from 'numeral';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import ChaptersMapStyles, { ChapterModal } from './chapters_map_styles';
import theme from '../../../common/theme';
import FlamelinkImage from '../../../util/flamelink_image';
import Button from '../../../common/components/button';

const propTypes = {
  currentChapter: PropTypes.shape({}),
  chapters: PropTypes.instanceOf(Map),
  showChapter: PropTypes.func.isRequired,
  closeChapter: PropTypes.func.isRequired,
  chapter: PropTypes.shape({}),
};

const defaultProps = {
  currentChapter: undefined,
  chapters: new Map(),
  chapter: undefined,
};

const ChaptersMap = ({ chapters, showChapter, closeChapter, currentChapter }) => (
  <ChaptersMapStyles>
    {chapters.valueSeq().map((chapter) => (
      <ChapterModal
        key={chapter.get('slug')}
        style={{ visibility: (currentChapter && chapter.get('slug') === currentChapter.get('slug')) ? 'visible' : 'hidden' }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="close" onClick={closeChapter} dangerouslySetInnerHTML={{ __html: '&times;' }} />
        <FlamelinkImage id={chapter.get('image').first()} width="150px" height="150px" />
        <h3>{chapter.get('city')}</h3>
        <div className="stats">
          <div className="row">
            <div className="col-md-12">
              <h4>Raised</h4>
              <h1>{numeral(chapter.get('fundsRaised')).format('$0,0')}M</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h4>Founder Pipeline</h4>
              <h1>{numeral(chapter.get('foundersPipeline')).format('0,0')}</h1>
            </div>
            <div className="col-md-6">
              <h4>Investor Pipeline</h4>
              <h1>{numeral(chapter.get('investorsPipeline')).format('0,0')}</h1>
            </div>
          </div>
        </div>
        {['new-york', 'washington-dc'].includes(chapter.get('slug')) ? (
          <Button link={`/chapters/${chapter.get('slug')}`} background={theme.color.secondary} color="#fff">
            More Info
          </Button>
        ) : null}
      </ChapterModal>
    ))}
    <GoogleMap
      defaultZoom={4.3}
      defaultCenter={{ lat: 42, lng: -95 }}
      defaultOptions={{
        disableDefaultUI: true,
        scrollwheel: false,
        draggable: true,
        styles: [
          { elementType: 'geometry', stylers: [{ color: theme.color.primary }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: theme.color.primary }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#8F3E36' }] },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#EE685B' }],
          },
        ],
      }}
    >
      {/* eslint-disable-next-line no-shadow */}
      {chapters.valueSeq().map((chapter) => (
        <Marker
          key={chapter.get('slug')}
          position={{ lat: chapter.get('latitude'), lng: chapter.get('longitude') }}
          icon="/images/map-marker.png"
          onClick={() => {
            // alert(chapter.get('city'));
            showChapter(chapter.get('slug'));
          }}
        />
      ))}
    </GoogleMap>
  </ChaptersMapStyles>
);

ChaptersMap.propTypes = propTypes;
ChaptersMap.defaultProps = defaultProps;

const ChaptersMapView = withScriptjs(withGoogleMap(ChaptersMap));

export default ChaptersMapView;
