import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import TeamStyles from './team_styles';
import Hero from '../hero';
import Snippet from '../snippet';
import ChaptersFilter from '../chapters_filter';
import Thumb from '../thumb';
import ThumbGrid from '../thumb_grid';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.instanceOf(Map),
  founder: PropTypes.instanceOf(Map),
  advisoryCommittee: PropTypes.instanceOf(Map),
  chapterDirectors: PropTypes.instanceOf(Map),
  ventureCommittee: PropTypes.instanceOf(Map),
  filter: PropTypes.string,
};

const defaultProps = {
  page: new Map(),
  founder: new Map(),
  advisoryCommittee: new Map(),
  chapterDirectors: new Map(),
  ventureCommittee: new Map(),
  filter: null,
};

const TeamView = ({ page, founder, advisoryCommittee, chapterDirectors, ventureCommittee, filter }) => (
  <TeamStyles>
    <PageMetaHead page={page} />
    {page.get('hero') && (
      <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      <section style={{ padding: '50px 0' }}>
        <div className="row">
          <div className="col-md-8">
            <h2>Founder / Ceo</h2>
            <Snippet slug="founder" />
          </div>
          <div className="col-md-4">
            <Thumb leader={founder} height="300px" />
          </div>
        </div>
      </section>
      <hr />
      {/*
      <section style={{ padding: '50px 0' }}>
        <h2>National Board</h2>
        <Snippet slug="national-board" />
        <ThumbGrid leaders={boardAdvisors} filter={filter} />
      </section>
      */}
      <section style={{ padding: '50px 0' }}>
        <h2>Chapter Teams</h2>
        <Snippet slug="chapter-teams" />
        <ChaptersFilter />
        <ThumbGrid leaders={chapterDirectors} filter={filter} />
      </section>
      <section style={{ padding: '50px 0' }}>
        <h2>Local Venture Committees</h2>
        <Snippet slug="local-venture-committees" />
        <ChaptersFilter />
        <ThumbGrid leaders={ventureCommittee} filter={filter} />
      </section>
      <section style={{ padding: '50px 0' }}>
        <h2>Local Advisory Committees</h2>
        <Snippet slug="advisory-committee" />
        <ChaptersFilter />
        <ThumbGrid leaders={advisoryCommittee} filter={filter} />
      </section>
    </div>
  </TeamStyles>
);

TeamView.propTypes = propTypes;
TeamView.defaultProps = defaultProps;

export default TeamView;
