import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from '../../../../common/form';
import validate from './validate';

const propTypes = {
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
};

const CompanyDescriptionField = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <p><strong>Does this describe your company?</strong></p>
    <ul>
      <li>At least one active member of the founding team is female</li>
      <li>Our product or service is software or Internet based, tech-enabled through mobile or web, or has patentable technology</li>
      <li>Our site, application or product is live, manufactured or in functioning Beta</li>
      <li>Our company has a scalable growth model, that is venture-backable</li>
    </ul>

    <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
      Previous
    </button>

    <button type="submit" className="btn btn-primary">
      Yes
    </button>
  </form>
);

CompanyDescriptionField.propTypes = propTypes;
CompanyDescriptionField.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CompanyDescriptionField);
