import React from 'react';
import PropTypes from 'prop-types';
import RolesFilterStyles from './roles_filter_styles';

const propTypes = {
  setFilterRole: PropTypes.func,
  role: PropTypes.instanceOf(Map),
  leaders: PropTypes.instanceOf(Map),
  filter: PropTypes.func,
};

const defaultProps = {
  setFilterRole: undefined,
  role: undefined,
  leaders: undefined,
  filter: undefined,
};

class RolesFilterView extends React.Component {
  setFilterRole(role) {
    const { setFilterRole } = this.props;

    setFilterRole(role);
  }

  render() {
    const { role, leaders, filter } = this.props;
    return (
      <RolesFilterStyles>
        <ul>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={role === null ? 'active' : ''} onClick={() => this.setFilterRole(null)}>
              All
            </a>
          </li>
          {leaders && leaders
            .filter((leader) => leader.get('chapter') === filter)
            .map((leader) => leader.get('role'))
            .toSet()
            .map((r) => (
              <li key={r}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={role === r ? 'active' : ''} onClick={() => this.setFilterRole(r)}>
                  {r}
                </a>
              </li>
            ))}
        </ul>
      </RolesFilterStyles>
    );
  }
}

RolesFilterView.propTypes = propTypes;
RolesFilterView.defaultProps = defaultProps;

export default RolesFilterView;
