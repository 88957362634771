import React from 'react';
import PropTypes from 'prop-types';
import BootcampStyles from './bootcamp_styles';
import Button from '../../../common/components/button';
import Snippet from '../snippet';
import Hero from '../hero';
// import Testimonials from '../testimonials';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.shape({}),
};

const defaultProps = {
  page: new Map(),
};

const BootcampView = ({ page }) => (
  <BootcampStyles>
    <PageMetaHead page={page} />
    {page && page.get('hero') && (
      <Hero background={page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      <section>
        <h1 className="primary">FULL DAY</h1>
        <h1 className="secondary">BOOTCAMP</h1>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        {page.get('ctaLink') && (
          <div style={{ textAlign: 'center' }}>
            <Button link={page.get('ctaLink')} target="_blank">
              {page.get('ctaText')}
            </Button>
          </div>
        )}
      </section>
      <section>
        <h2 className="primary">Why attend a Bootcamp?</h2>
        <Snippet slug="bootcamp-why-attend" />
        <div className="row">
          <div className="col-md-4">
            <Snippet slug="bootcamp-why-attend-reason-1" imageHeight="150px" />
          </div>
          <div className="col-md-4">
            <Snippet slug="bootcamp-why-attend-reason-2" imageHeight="150px" />
          </div>
          <div className="col-md-4">
            <Snippet slug="bootcamp-why-attend-reason-3" imageHeight="150px" />
          </div>
        </div>
      </section>
      <section>
        <h2 className="primary" style={{ textAlign: 'center' }}>The Schedule</h2>
        <Snippet slug="bootcamp-the-schedule" />
      </section>

    </div>

    {/* <Testimonials category="bootcamp" /> */}

    <div className="container">
      <section>
        <h2 className="primary">Apply</h2>
        <div className="bootstrap-apply-subheader">I am a CEO / Founder <span>Looking To...</span></div>
        <Snippet slug="bootcamp-apply-founder" />

        <div className="row">
          <div className="col-md-6">
            <Snippet slug="bootcamp-apply-founder-reason-1" />
          </div>
          <div className="col-md-6">
            <Snippet slug="bootcamp-apply-founder-reason-2" />
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button link="" target="_blank">
     Apply as a Founder
          </Button>
        </div>
      </section>
      <hr />
      <section>
        <div className="bootstrap-apply-subheader">I am an Industry Expert <span>Looking To...</span></div>
        <Snippet slug="bootcamp-apply-expert" />

        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Snippet slug="bootcamp-apply-expert-reason-1" />
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button link="" target="_blank">
      Apply as an Expert
          </Button>
        </div>
      </section>
    </div>
  </BootcampStyles>
);

BootcampView.propTypes = propTypes;
BootcampView.defaultProps = defaultProps;

export default BootcampView;
