import styled from 'styled-components';

const NavDrawerStyles = styled.div`
  background: ${(props) => props.theme.color.secondary};
  width: 340px;
  position: absolute;
  top: -20px;
  right: -20px;
  height: calc(100vh);
  padding: ${(props) => props.theme.padding.large * 4}px ${(props) => props.theme.padding.large * 2}px;
  i {
    cursor: pointer;
    font-size: 35px;
    position: absolute;
    top: 20px;
    right: 24px;
    color: ${(props) => props.theme.color.primary};
    float: right;
    &:hover {
      color: ${(props) => props.theme.color.light};
    }
    &:active {
      color: ${(props) => props.theme.color.dark};
    }
  }
  h3 {
    margin: 10px 0;
    color: ${(props) => props.theme.color.primary};
    font-size: ${(props) => props.theme.text.fontSize * 2}px;
  }
  ul {
    list-style: none;
    padding: 0px;
    li {
      display: block;
      text-align: right;
    }
    li a {
      text-decoration: none;
      color: ${(props) => props.theme.color.light};
      line-height: 1.5em;
      &:hover,
      .active {
        color: ${(props) => props.theme.color.primary};
      }
      &:active {
        color: ${(props) => props.theme.color.dark};
      }
    }
    &.large li a {
      font-size: ${(props) => props.theme.text.fontSize * 1.75}px;
      font-weight: bold;
    }
    li.small a {
      font-size: ${(props) => props.theme.text.fontSize * 0.6}px;
      line-height: 2em;
      letter-spacing: 0.25em;
      font-weight: lighter;
    }
  }
`;

export default NavDrawerStyles;
