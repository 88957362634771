import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  FormSelect,
  MultiSelect,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const YES_NO_OPTIONS = [
  ['yes', 'Yes'],
  ['no', 'No'],
];

const KPI_OPTIONS = [
  'Sales',
  'Conversions',
  'Users',
  'Page Views',
  'Retention',
  'Other',
];

const TractionFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Traction</h4>

      <div className="col-sm-12">
        <Field
          component={FormSelect}
          options={YES_NO_OPTIONS}
          label="Do you currently generate revenue?"
          name="profile['data']['current_revenue']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['total_revenue']"
          component={TextField}
          label="What is your Total Revenue to Date?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['kpis']"
          component={MultiSelect}
          options={KPI_OPTIONS}
          label="Select your key performance metrics."
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['traction_growth']"
          component={TextField}
          label="Tells us more about the stats that best reflect your traction/growth (Max 300 characters)"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['burn_rate']"
          component={TextField}
          label="What is your monthly burn (include salaries)?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

TractionFields.propTypes = propTypes;
TractionFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(TractionFields);
