import React from 'react';
import { Map } from 'immutable';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import AboutStyles from './about_styles';
import Hero from '../hero';
import Snippet from '../snippet';
import JoinUs from '../join_us';
import Variable from '../variable';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.shape({}),
};

const defaultProps = {
  page: new Map(),
};

const AboutView = ({ page }) => (
  <AboutStyles>
    <PageMetaHead page={page} />
    {page.get('hero') && (
      <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      <section>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        <div className="gender-icons">
          <img src="/images/gender-icons.png" alt="Genders" />
        </div>
      </section>
      <hr />
      <div className="row infographics">
        <div className="col-md-4">
          <div className="infographic">
            <h5>Annual Revenue</h5>
            <div className="image-container">
              <img src="/images/infographic-annual-revenue.jpg" alt="Annual Revenue" />
            </div>
            <Snippet slug="annual-revenue" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="infographic">
            <h5>Investor Returns</h5>
            <div className="image-container">
              <img src="/images/infographic-investor-returns.jpg" alt="Investor Returns" />
            </div>
            <Snippet slug="investor-returns" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="infographic">
            <h5>Company Performance</h5>
            <div className="image-container">
              <img src="/images/infographic-company-performance.jpg" alt="Company Performance" />
            </div>
            <Snippet slug="company-performance" />
          </div>
        </div>
      </div>
      <div className="row infographics">
        <div className="col-md-4">
          <div className="infographic">
            <div className="image-container">
              <img src="/images/infographic-venture-dollars.jpg" alt="Venture Dollars" />
            </div>
            <h5>of all venture dollars</h5>
            <Snippet slug="venture-dollars" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="infographic">
            <div className="image-container">
              <img src="/images/infographic-investors-say.jpg" alt="Investors Say" />
            </div>
            <h5>of investors say</h5>
            <Snippet slug="investors-say" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="infographic">
            <div className="image-container">
              <img src="/images/infographic-trillion-dollars.jpg" alt="Trillion Dollars" />
            </div>
            <h5>trillion dollars</h5>
            <Snippet slug="trillion-dollars" />
          </div>
        </div>
      </div>
      <hr />
      <section className="pillars">
        <h2 className="primary">Our 4 Capital Pillars</h2>
        <Snippet slug="capital-pillars" />
        <div className="row">
          <div className="col-xs-6 col-md-3">
            <div className="pillar center">
              <ReactSVG src="/vector/pillar-funding.svg" />
              <h5>Financial Capital</h5>
              <Snippet slug="pillar-financial" />
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <div className="pillar center">
              <ReactSVG src="/vector/pillar-education.svg" />
              <h5>Intellectual Capital</h5>
              <Snippet slug="pillar-intellectual" />
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <div className="pillar center">
              <ReactSVG src="/vector/pillar-network.svg" />
              <h5>Social Capital</h5>
              <Snippet slug="pillar-social" />
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <div className="pillar center">
              <ReactSVG src="/vector/pillar-leadership.svg" />
              <h5>Personal Capital</h5>
              <Snippet slug="pillar-personal" />
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h3>Results</h3>
        <Snippet slug="results" />
        <div className="row stats">
          <div className="col-md-4 center">
            <h1 className="primary">
              <Variable slug="funds-raised" animate prefix="$" suffix="M" />
            </h1>
            <h5>Million</h5>
            <small>
              <p>in funding to Vinetta Showcase finalists</p>
            </small>
          </div>
          <div className="col-md-4 center">
            <h1 className="primary">
              <Variable slug="founder-count" animate />
            </h1>
            <h5>Founders</h5>
            <small>
              <p>in our current community</p>
            </small>
          </div>
          <div className="col-md-4 center">
            <h1 className="primary">
              <Variable slug="exit-count" animate />
            </h1>
            <h5>Exits</h5>
            <small>
              <p>from founders in our Pitch Portfolio</p>
            </small>
          </div>
        </div>
      </section>
      <hr />
      <section className="about-cta-header">
        <h2 className="primary">Vinetta has the potential to close the gender-based funding gap within 20 years</h2>
      </section>
      <section className="about-cta">
        <h3>Join Us</h3>
        <div style={{ textAlign: 'center' }}>
          <JoinUs />
        </div>
      </section>
    </div>
  </AboutStyles>
);

AboutView.propTypes = propTypes;
AboutView.defaultProps = defaultProps;

export default AboutView;
