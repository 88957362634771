import React from 'react';
import PropTypes from 'prop-types';
import PageStyles from './page_styles';
import Hero from '../hero';
import Gallery from '../../../util/gallery';
import Snippet from '../snippet';
import VimeoHero from '../vimeo_hero';
import MetaHead from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.instanceOf(Map),
};

const defaultProps = {
  page: new Map(),
};

const PageView = ({ page }) => (
  <PageStyles>
    <MetaHead title={page.get('title')} description={page.get('metaDescription')} keywords={page.get('metaKeywords')} />
    {page.get('vimeoVideoId') && <VimeoHero id={page.get('vimeoVideoId')} />}
    {page.get('hero') && page.get('hero') !== '<p></p>\n' && (
      <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      {page.get('body') && page.get('body') !== '<p></p>\n' ? (
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        </section>
      ) : null}
      {page.get('snippets') && (
        <div className="row">
          {page.get('snippets').map((id) => (
            <div key={id} className="col-md-6">
              <Snippet id={id} />
            </div>
          ))}
        </div>
      )}
      {page.get('gallery') && <Gallery photos={page.get('gallery').toJS()} />}
    </div>
  </PageStyles>
);

PageView.propTypes = propTypes;
PageView.defaultProps = defaultProps;

export default PageView;
