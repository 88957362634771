import React from 'react';
import PropTypes from 'prop-types';
import Gallery from '../../../util/gallery';
import ProgrammingStyles from './programming_styles';
import Snippet from '../snippet';
import Hero from '../hero';
import EventsUpcoming from '../events_upcoming';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.shape({}),
  gallery: PropTypes.shape({}),
  chapters: PropTypes.shape({}),
};

const defaultProps = {
  page: new Map(),
  gallery: new Map(),
  chapters: new Map(),
};

const ProgrammingView = ({ page, gallery, chapters }) => (
  <ProgrammingStyles>
    <PageMetaHead page={page} />
    {/* <VimeoHero id="206662567" /> */}
    {page && page.get('hero') && (
      <Hero background={page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      <section>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        <div style={{ height: 60 }} />
        <div className="row">
          <div className="col-md-3">
            <Snippet slug="showcase-pitch-panel" imageHeight="180px" />
          </div>
          <div className="col-md-3">
            <Snippet slug="problem-hacking-mentorship" imageHeight="180px" />
          </div>
          <div className="col-md-3">
            <Snippet slug="founders-funders-dinner" imageHeight="180px" />
          </div>
          <div className="col-md-3">
            <Snippet slug="half-day-bootcamp" imageHeight="180px" />
          </div>
        </div>
      </section>

      <EventsUpcoming />

      {/* Recent Events */}
      { (gallery && gallery.size) ? (
        <section>
          <h2 className="primary">Recent Events</h2>
          <Gallery gallery={gallery} chapters={chapters} />
        </section>
      ) : null}

      <section>
        <h2 className="primary">Partner Programs</h2>
        <div style={{ height: 30 }} />
        <div className="row">
          <div className="col-md-4">
            <Snippet slug="partner-program-booz-allen-hamilton" />
          </div>
          <div className="col-md-4">
            <Snippet slug="partner-program-procter-gamble" />
          </div>
          <div className="col-md-4">
            <Snippet slug="partner-program-jp-morgan" />
          </div>
        </div>
      </section>
    </div>
  </ProgrammingStyles>
);

ProgrammingView.propTypes = propTypes;
ProgrammingView.defaultProps = defaultProps;

export default ProgrammingView;
