import { reducer as FormReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { combineImmutableReducers } from './util';
import SessionReducer, * as SessionSelect from './session/reducer';
import GlobalSettingsReducer, * as GlobalSettingsSelectors from './global_settings/reducer';
import HubSpotFormReducer, { getContactFormStatus } from './hub_spot_form/reducer';
import { ContentReducer, ContentSelectors } from './vendor/flamelink';
import VinettaReducer, * as VinettaSelect from './vinetta/reducer';

const Reducer = (history) =>
  combineImmutableReducers({
    router: connectRouter(history),
    content: ContentReducer,
    form: FormReducer,
    session: SessionReducer,
    globalSettings: GlobalSettingsReducer,
    hubSpotForm: HubSpotFormReducer,
    vinetta: VinettaReducer,
  });

export default Reducer;

// Flamelink Content
export const getContent = (state, schema) => ContentSelectors.getContent(state.get('content'), schema);
export const getContentBySlug = (state, schema, slug) => ContentSelectors.getContentBySlug(state.get('content'), schema, slug);
export const getContentByID = (state, schema, id) => ContentSelectors.getContentByID(state.get('content'), schema, id);
export const getContentByProp = (state, schema, key, value) => ContentSelectors.getContentByProp(state.get('content'), schema, key, value);

// HubSpot Selectors
export const getHubSpotContactFormStatus = (state) => getContactFormStatus(state.get('hubSpotForm'));

export const getGlobalSetting = (state, key, notSetValue = undefined) => GlobalSettingsSelectors.getSetting(state.globalSettings, key, notSetValue);
export const getFlashList = (state) => SessionSelect.getFlashList(state.get('session'));
export const getCurrentUser = (state) => SessionSelect.getCurrentUser(state.get('session'));
export const getTreatment = (state, experiemnt, defaultTreatment) => SessionSelect.getTreatment(state.get('session'), experiemnt, defaultTreatment);

// Vinetta
export const getTitle = (state) => VinettaSelect.getTitle(state.get('vinetta'));
export const getNavDrawer = (state) => VinettaSelect.getNavDrawer(state.get('vinetta'));
export const getMapChapter = (state) => VinettaSelect.getMapChapter(state.get('vinetta'));
export const getChapterFilter = (state) => VinettaSelect.getChapterFilter(state.get('vinetta'));
export const getRoleFilter = (state) => VinettaSelect.getRoleFilter(state.get('vinetta'));

export const getCurrentRoute = (state) => state.getIn(['router', 'location', 'pathname']);
