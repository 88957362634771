import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import numeral from 'numeral';
import Gallery from '../../../util/gallery';
import ChapterStyles from './chapter_styles';
import TeamStyles from '../team/team_styles';
import Hero from '../hero';
import EventsUpcoming from '../events_upcoming';
import Sponsors from '../sponsors';
import Button from '../../../common/components/button';
import Snippet from '../snippet';
import ThumbGrid from '../thumb_grid';
import RolesFilter from '../roles_filter';
// import Gallery from '../../../util/gallery';
import MetaHead from '../../../util/meta_head';

const propTypes = {
  chapter: PropTypes.instanceOf(Map),
  leaders: PropTypes.instanceOf(Map),
  roleFilter: PropTypes.string,
  gallery: PropTypes.instanceOf(Map),
};

const defaultProps = {
  chapter: new Map(),
  leaders: new Map(),
  roleFilter: undefined,
  gallery: new Map(),
};

const ChapterView = ({ chapter, leaders, roleFilter, gallery }) => (
  <ChapterStyles>
    <MetaHead
      title={`Chapter / ${chapter.get('city')}, ${chapter.get('state')}`}
      description={chapter.get('metaDescription')}
      keywords={chapter.get('metaKeywords')}
    />
    <Hero background={chapter.get('heroBackground') && chapter.get('heroBackground').first()}>
      <section style={{ textAlign: 'center' }}>
        <strong>Chapter</strong>
        <h2>{chapter && chapter.get('title')}</h2>
        {chapter.get('impactReport') && (
          <Button
            background="#fff"
            borderless
            link={chapter
              .get('impactReport')
              .first()
              .get('url')}
          >
            Download Impact Report
          </Button>
        )}
        <div className="stats">
          <div className="row">
            <div className="col-xs-4">
              <h4>
                Capital
                <br />
                Raised
              </h4>
              <h1>{numeral(chapter.get('fundsRaised')).format('$0,0')}M</h1>
            </div>
            <div className="col-xs-4">
              <h4>
                Founder
                <br />
                Pipeline
              </h4>
              <h1>{numeral(chapter.get('foundersPipeline')).format('0,0')}</h1>
            </div>
            <div className="col-xs-4">
              <h4>
                Investor
                <br />
                Pipeline
              </h4>
              <h1>{numeral(chapter.get('investorsPipeline')).format('0,0')}</h1>
            </div>
          </div>
        </div>
      </section>
    </Hero>

    <div className="container">
      <div className="body">
        {chapter.get('body') && chapter.get('body') !== '<p></p>\n' ? (
          <section style={{ padding: '20px 0 50px 0' }}>
            <div dangerouslySetInnerHTML={{ __html: chapter.get('body') }} />
          </section>
        ) : (
          <section>
            <center>Coming Soon...</center>
          </section>
        )}
      </div>
      <div className="row">
        <div className="col-md-3">
          <small>
            <Snippet slug="showcase-pitch-panel" imageHeight="180px" />
          </small>
        </div>
        <div className="col-md-3">
          <small>
            <Snippet slug="problem-hacking-mentorship" imageHeight="180px" />
          </small>
        </div>
        <div className="col-md-3">
          <small>
            <Snippet slug="dinners" imageHeight="180px" />
          </small>
        </div>
        <div className="col-md-3">
          <small>
            <Snippet slug="half-day-bootcamp" imageHeight="180px" />
          </small>
        </div>
      </div>

      <section style={{ textAlign: 'center' }}>
        <Button link="https://app.vinettaproject.com/data_sets/fQhFe3gvwZCeYOYo">
    Apply for our programming
        </Button>
      </section>

      {/* Upcoming Events */}
      <section style={{ paddingBottom: 0 }}>
        <EventsUpcoming chapter={chapter} />
      </section>

      <section>
        <h2 className="primary">Team</h2>
        <Snippet slug="chapter-teams" />
        <RolesFilter leaders={leaders} filter={chapter.get('id')} />
        <TeamStyles>
          <ThumbGrid leaders={leaders} filter={chapter.get('id')} roleFilter={roleFilter} />
        </TeamStyles>
      </section>

      <section>
        <Sponsors chapter={chapter.get('id')} />
      </section>

      {/* Recent Events */}
      { (gallery && gallery.filter((item) => item.get('chapter') === chapter.get('id')).size) ? (
        <section>
          <h2 className="primary">Recent Events</h2>
          <Gallery gallery={gallery.filter((item) => item.get('chapter') === chapter.get('id'))} chapters={new List([chapter])} />
        </section>
      ) : null}
    </div>
  </ChapterStyles>
);

ChapterView.propTypes = propTypes;
ChapterView.defaultProps = defaultProps;

export default ChapterView;
