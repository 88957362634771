import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  FormSelect,
  MultiSelect,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const OTHER_EVENT_OPTIONS = [
  'Workshops',
  'Fireside Chats',
  'Office Hours',
  '1-on-1 Mentorships',
  'Networking Events',
  'Dinners',
  'Happy Hours / Mixers',
  'Bootcamps / Accelerators',
  'Other',
];

const AFFILIATION_OPTIONS = [
  'Techstars',
  '500 Startups',
  'Ycombinator',
  'YPO',
  'Summit',
  'Women 2.0',
  'Dreamers/Doers',
  'Women Who Code',
  'Other',
];

const HEARD_FROM_OPTIONS = [
  ['web', 'Online/Social Media'],
  ['company', 'Company/Organization'],
  ['newsletter', 'Newsletter'],
  ['friend', 'Friend/Colleague'],
  ['other', 'Other'],
];

const CONNECTION_OPTIONS = [
  'Capital Closed',
  'Talent/Recruitment',
  'Business Development',
  'Accounting/Finance',
  'Tech/Engineering',
  'Advisor/Mentor',
  'Not Applicable',
  'Other',
];

const CHAPTER_OPTIONS = [
  ['new_york', 'New York'],
  ['toronto', 'Toronto'],
  ['washington_dc', 'Washington D.C.'],
  ['san_francisco', 'San Francisco'],
  ['vancouver', 'Vancouver'],
  ['los_angeles', 'Los Angeles'],
  ['boulder', 'Boulder'],
];

const EVENT_OPTIONS = [
  ['yes', 'Yes'],
  ['no', 'No'],
];

const NEED_OPTIONS = [
  'Capital/Investors',
  'Recruitment',
  'Marketing/Growth Hacking',
  'Advisors (Finance)',
  'Advisors (Tech/Engineering)',
  'Advisors (Business Development)',
  'Other',
];

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const BackgroundInformationFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Background Information</h4>

      <div className="col-sm-12">
        <Field component={TextField} label="Full Name" name="profile['data']['full_name']" fullWidth />
      </div>

      <div className="col-sm-12">
        <Field component={TextField} label="Position in Company" name="profile['data']['position']" fullWidth />
      </div>

      <div className="col-sm-12">
        <Field component={TextField} label="Phone Number" name="profile['data']['phone']" fullWidth />
      </div>

      <div className="col-sm-12">
        <Field component={TextField} label="Linkedin Profile" name="profile['data']['linkedin_profile']" fullWidth />
      </div>

      <div className="col-sm-12">
        <Field
          component={FormSelect}
          options={HEARD_FROM_OPTIONS}
          label="How did you hear of Vinetta project?"
          name="profile['data']['heard_from']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          component={FormSelect}
          options={CHAPTER_OPTIONS}
          label="Which Vinetta Chapter is closest to your company's HQ?"
          name="profile['data']['chapter']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          component={FormSelect}
          options={EVENT_OPTIONS}
          label="Have you attended a Vinetta event?"
          name="profile['data']['attended_event']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          fullWidth
          component={MultiSelect}
          options={CONNECTION_OPTIONS}
          name="profile['data']['connections']"
          label="Have you made any successful connections through the Vinetta community?"
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['founder_needs']"
          component={MultiSelect}
          options={NEED_OPTIONS}
          label="What are the top 3 things you need as a Founder?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['affiliations']"
          component={MultiSelect}
          options={AFFILIATION_OPTIONS}
          label="Do you have current affiliations with other such organizations like Vinetta?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['other_events']"
          component={MultiSelect}
          options={OTHER_EVENT_OPTIONS}
          label="Which other types of Vinetta events are you interested in attending?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-secondary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

BackgroundInformationFields.propTypes = propTypes;
BackgroundInformationFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(BackgroundInformationFields);
