import styled from 'styled-components';

const PitchAndPanelStyles = styled.div`
.pitch-and-panel-results-companies {
  text-align: center;
  .snippet-text {
    h5 {
      color: ${(props) => props.theme.color.primary};
      font-size: 18px;
    }
  }
}
.pitch-and-panel-program-item {
  text-align: center;
  min-height: 220px;

  .snippet-text {
    margin: 5px 0 0;

    h5 {
      font-size: 0.8em;
    }

    small p {
      text-align: left;
      font-size: 0.7em;
    }
  }
}
`;

export default PitchAndPanelStyles;
