import styled from 'styled-components';
import HeroStyles from '../hero/hero_styles';

const ChapterStyles = styled.div`
  ${HeroStyles} {
    .background::after {
      background-color: #000000;
      opacity: 0.4;
    }
    strong {
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    h2 {
      font-size: 3.5em;
      @media screen and (max-width: 650px) {
        font-size: 3em;
      }
    }
    .button > * {
      margin-top: 20px;
      transform: scale(0.75);
    }
    .stats {
      margin-top: 50px;
    }
    .content {
      @media screen and (max-width: 650px) {
        padding: 0;
        width: 100%;
        transform: scale(0.8);
        .stats h1 {
          font-size: 2.2em;
        }
      }
    }
  }
  .body {
    h2 {
      margin: 0;
      padding: 50px 0 10px;
      color: ${(props) => props.theme.color.primary};
    }
    img {
      margin: 10px 40px;
    }
    p {
      font-size: 0.75em;
    }
  }
`;

export default ChapterStyles;
