/* eslint-disable filenames/match-exported */
// import { fromJS } from 'immutable';
import { combineImmutableReducers } from '../../util';
import ACTIONS from '../actions';
// const DEFAULT_MAP = new Map();
// const DEFAULT_LIST = new List();

export const TitleReducer = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.TITLE_SET:
      return action.title;
    case ACTIONS.ROUTER_LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

export const NavDrawerReducer = (state = 'hide', action) => {
  switch (action.type) {
    case ACTIONS.SHOW_DRAWER:
      return 'show';
    case ACTIONS.HIDE_DRAWER:
      return 'hide';
    case ACTIONS.TOGGLE_DRAWER:
      return state === 'hide' ? 'show' : 'hide';
    default:
      return state;
  }
};

export const MapChapterReducer = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_MAP_CHAPTER:
      return action.chapter || null;
    case ACTIONS.CLOSE_MAP_CHAPTER:
    case ACTIONS.ROUTER_LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

export const ChapterFilterReducer = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_CHAPTER_FILTER:
      return action.chapter || null;
    default:
      return state;
  }
};

export const RoleFilterReducer = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_ROLE_FILTER:
      return action.role || null;
    default:
      return state;
  }
};

const VinettaReducer = combineImmutableReducers({
  title: TitleReducer,
  navDrawer: NavDrawerReducer,
  mapChapter: MapChapterReducer,
  filter: ChapterFilterReducer,
  roleFilter: RoleFilterReducer,
});

export default VinettaReducer;

// Selectors
export const getTitle = (state) => state.get('title');
export const getNavDrawer = (state) => state.get('navDrawer');
export const getMapChapter = (state) => state.get('mapChapter');
export const getChapterFilter = (state) => state.get('filter');
export const getRoleFilter = (state) => state.get('roleFilter');
