import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import {
  CarouselProvider, Slider, Slide, ButtonBack, ButtonNext,
} from 'pure-react-carousel';
import { withSize } from 'react-sizeme';
import PartnershipStyles from './partnership_styles';
import Button from '../../../common/components/button';
import Hero from '../hero';
import Testimonials from '../testimonials';
import FlamelinkImage from '../../../util/flamelink_image';
import Snippet from '../snippet';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.instanceOf(Map),
  category: PropTypes.string,
  size: PropTypes.shape({ height: PropTypes.number, width: PropTypes.number }).isRequired,
  setTitle: PropTypes.func.isRequired,
};

const defaultProps = {
  page: new Map(),
  category: '',
};

const PartnershipView = ({ page, category, size, setTitle }) => {
  useEffect(() => { if (page && page.get('title')) setTitle(page.get('title')); });

  return (
    <PartnershipStyles>
      <PageMetaHead page={page} />
      {page.get('hero') && page.get('hero') !== '<p></p>\n' && (
        <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
          <section>
            <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
          </section>
        </Hero>
      )}
      <div className="container page-body">
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
          {page.get('applyLink') && (
            <div style={{ textAlign: 'center' }}>
              <Button link={page.get('applyLink')} target="_blank">
          Apply Now
              </Button>
            </div>
          )}
        </section>
        <hr />
        {page.get('programming') && page.get('programming').size > 0 && (
          <section>
            <div className="row">
              {page.get('programming').slice(0, 4).map((item) => (
                <div key={item.get('snippetSlug')} className={`col-md-${(12 / page.get('programming').slice(0, 4).size)}`}>
                  <h5>{item.get('title')}</h5>
                  <small>
                    <Snippet slug={item.get('snippetSlug')} />
                  </small>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>

      <Testimonials category={category} />

      <div className="partner-brands">
        <section>
          <div className="container">
            <h2 className="primary">{page.get('partnerBrandsTitle')}</h2>
            <Snippet slug={`partnership-${category}-who-is`} />
          </div>
          {page.get('partnerBrands') && (
            <div className="carousel-container">
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={page.get('partnerBrands').size}
                visibleSlides={1}
                currentSlide={1}
                step={1}
                interval={6000}
              >
                {size.width > 640 && (
                <>
                  <ButtonBack>
                    <i className="material-icons">chevron_left</i>
                  </ButtonBack>
                  <ButtonNext>
                    <i className="material-icons">chevron_right</i>
                  </ButtonNext>
                </>
                )}
                <Slider>
                  {page.get('partnerBrands').map((brand, index) => (
                    <Slide key={brand.get('id')} index={index}>
                      <div className="brand-slide">
                        <FlamelinkImage id={brand.get('image').first()} />
                        <div dangerouslySetInnerHTML={{ __html: brand.get('description') }} />
                      </div>
                    </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
            </div>
          )}
        </section>
      </div>

      {page.get('highlightTitle') && (
        <div className="container">
          <section>
            <h2 className="primary">{page.get('highlightTitle')}</h2>
            <Snippet
              slug={page.get('highlightSnippetSlug')}
              imageHeight="150px"
              imageWidth="150px"
              imageStyle={{ float: 'left', marginRight: '20px', marginBottom: '20px', boxShadow: '0px 0px 6px rgba(0,0,0,0.25)' }}
            />
          </section>
        </div>
      )}

      <div className="container">
        {page.get('thesisEligibility') && (
          <section>
            <h2 className="primary">Areas of Interest</h2>
            <Snippet slug={`partnership-${category}-eligibility`} />
            <div className={`program-eligibility program-eligibility-size-${page.get('thesisEligibility').size > 5 ? 'small' : 'normal'}`}>
              <Tabs>
                <TabList>
                  {page.get('thesisEligibility').map((item) => (
                    <Tab key={item.get('id')}>{item.get('title')}</Tab>
                  ))}
                </TabList>
                {page.get('thesisEligibility').map((item) => (
                  <TabPanel key={item.get('id')}>
                    <small>
                      <div dangerouslySetInnerHTML={{ __html: item.get('description') }} />
                    </small>
                  </TabPanel>
                ))}
              </Tabs>
              {page.get('thesisEligibilityApplyLink') && (
                <Button link={page.get('thesisEligibilityApplyLink')} target="_blank">
              Apply Now
                </Button>
              )}
            </div>
          </section>
        )}

        {page.get('mentors') && (
          <section className="mentors">
            <h2 className="primary">Problem Hacking: Meet your Mentors</h2>
            <Snippet slug={`partnership-${category}-mentors`} />
            <div className="row">
              {page.get('mentors').map((item) => (
                <div className="col-xs-6 col-sm-4 col-md-3" key={item.get('id')}>
                  <div className="mentor" onClick={() => item.get('url') && window.open(item.get('url'))}>
                    {item.get('image') && <FlamelinkImage id={item.get('image').first()} width="100%" height="100%" />}
                    <div className="text">
                      <span>
                        <strong>{item.get('name')}</strong>
                        <p>{item.get('role')}</p>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
        {page.get('footerText') && (
          <section className="footerText">
            <div dangerouslySetInnerHTML={{ __html: page.get('footerText') }} />
          </section>
        )}

      </div>
    </PartnershipStyles>
  );
};

PartnershipView.propTypes = propTypes;
PartnershipView.defaultProps = defaultProps;

export default withSize()(PartnershipView);
