import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StatisticsStyles = styled.section`
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    width: 100%;
    position: relative;
    text-align: center;
    ${breakpoint('sm')`
      width: 33.3333%;
      padding-top: 33.3333%;
    `}
  }
  li > div {
    padding: 40px 30px;
    border-color: #C3D2C7;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    ${breakpoint('sm')`
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-width: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}
  }
  
  li:nth-child(3n+1) > div {
    ${breakpoint('sm')`
      border-right-width: 1px;
    `}
  }
  li:nth-child(3n+0) > div {
    ${breakpoint('sm')`
      border-left-width: 1px;
    `}
  }
  li:nth-child(-n+3) > div {
    ${breakpoint('sm')`
      border-bottom-width: 1px;
    `}
  }
  h4 {
    font-size: ${(props) => props.theme.text.fontSize * 0.7}px;
    ${breakpoint('md')`
      font-size: ${(props) => props.theme.text.fontSize * 1}px;
    `}
    ${breakpoint('md')`

    `}
  }
  h2 {
    font-weight: lighter;
    font-size: ${(props) => props.theme.text.fontSize * 2}px;
    ${breakpoint('md')`
      font-size: ${(props) => props.theme.text.fontSize * 3}px;
    `}
    ${breakpoint('lg')`
      font-size: ${(props) => props.theme.text.fontSize * 3.4}px;
    `}
  }

`;

export default StatisticsStyles;
