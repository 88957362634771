import { connect } from 'react-redux';
import AngelistFormView from './angelist_form_view';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (_) => dispatch(),
});

const AngelistFormContainer = connect(mapStateToProps, mapDispatchToProps)(AngelistFormView);

export default AngelistFormContainer;
