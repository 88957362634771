import React from 'react';
import { FacebookButton, EmailButton, TwitterButton, LinkedInButton } from 'react-social';
import ReactSVG from 'react-svg';
import SocialSharingStyles from './social_sharing_styles';

const SocialSharingView = () => (
  <SocialSharingStyles>
    <FacebookButton appId={396200201200347}>
      <ReactSVG src="/vector/facebook-square.svg" />
    </FacebookButton>
    <TwitterButton>
      <ReactSVG src="/vector/twitter-square.svg" />
    </TwitterButton>
    <LinkedInButton>
      <ReactSVG src="/vector/linkedin-square.svg" />
    </LinkedInButton>
    <EmailButton>
      <ReactSVG src="/vector/envelope-square.svg" />
    </EmailButton>
  </SocialSharingStyles>
);

export default SocialSharingView;
