import styled from 'styled-components';

const ChaptersStyles = styled.div`
  .pillars {
    .pillar {
      padding: 30px;
      .icon {
        float: left;
        margin: -30px 10px 0 0;
      }
    }
  }
`;

export default ChaptersStyles;
