import validator from 'validator';

const REQUIRED = 'Required';

const validate = (values) => {
  const errors = { profile: { data: { } } };

  // TODO : Iterate over required fields list...
  //
  // Manually adding validations for now to make sure all fields are present
  if (!values.getIn(['profile', 'data', 'burn_rate'])) errors.profile.data.burn_rate = REQUIRED;
  if (!values.getIn(['profile', 'data', 'heard_from'])) errors.profile.data.heard_from = REQUIRED;
  if (!values.getIn(['profile', 'data', 'chapter'])) errors.profile.data.chapter = REQUIRED;
  if (!values.getIn(['profile', 'data', 'attended_event'])) errors.profile.data.attended_event = REQUIRED;
  if (!values.getIn(['profile', 'data', 'connections'])) errors.profile.data.connections = REQUIRED;
  if (!values.getIn(['profile', 'data', 'founder_needs'])) errors.profile.data.founder_needs = REQUIRED;
  if (!values.getIn(['profile', 'data', 'affiliations'])) errors.profile.data.affiliations = REQUIRED;
  if (!values.getIn(['profile', 'data', 'other_events'])) errors.profile.data.other_events = REQUIRED;
  if (!values.getIn(['profile', 'data', 'company_name'])) errors.profile.data.company_name = REQUIRED;
  if (!values.getIn(['profile', 'data', 'company_age'])) errors.profile.data.company_age = REQUIRED;
  if (!values.getIn(['profile', 'data', 'other_events'])) errors.profile.data.other_events = REQUIRED;
  if (!values.getIn(['profile', 'data', 'website'])) errors.profile.data.website = REQUIRED;
  if (values.getIn(['profile', 'data', 'website']) && !validator.isURL(values.getIn(['profile', 'data', 'website']))) {
    errors.profile.data.website = 'Invalid URL';
  }
  if (!values.getIn(['profile', 'data', 'sectors'])) errors.profile.data.sectors = REQUIRED;
  if (!values.getIn(['profile', 'data', 'company_description'])) errors.profile.data.company_description = REQUIRED;
  if (!values.getIn(['profile', 'data', 'customer_problem'])) errors.profile.data.customer_problem = REQUIRED;
  if (!values.getIn(['profile', 'data', 'revenue'])) errors.profile.data.revenue = REQUIRED;
  if (!values.getIn(['profile', 'data', 'go_to_market'])) errors.profile.data.go_to_market = REQUIRED;
  if (!values.getIn(['profile', 'data', 'employee_count'])) errors.profile.data.employee_count = REQUIRED;
  if (!values.getIn(['profile', 'data', 'divisions'])) errors.profile.data.divisions = REQUIRED;
  if (!values.getIn(['profile', 'data', 'part_time_employee_count'])) errors.profile.data.part_time_employee_count = REQUIRED;
  if (!values.getIn(['profile', 'data', 'part_time_divisions'])) errors.profile.data.part_time_divisions = REQUIRED;
  if (!values.getIn(['profile', 'data', 'qualification'])) errors.profile.data.qualification = REQUIRED;
  if (values.getIn(['profile', 'data', 'qualification']) && values.getIn(['profile', 'data', 'qualification']).length > 100) {
    errors.profile.data.qualification = 'Exceeds 100 character limit';
  }
  if (!values.getIn(['profile', 'data', 'competitors'])) errors.profile.data.competitors = REQUIRED;
  if (!values.getIn(['profile', 'data', 'company_information'])) errors.profile.data.company_information = REQUIRED;
  if (!values.getIn(['profile', 'data', 'proprietary'])) errors.profile.data.proprietary = REQUIRED;
  if (!values.getIn(['profile', 'data', 'proprietary'])) errors.profile.data.proprietary = REQUIRED;
  if (!values.getIn(['profile', 'data', 'partnerships'])) errors.profile.data.partnerships = REQUIRED;
  if (!values.getIn(['profile', 'data', 'current_revenue'])) errors.profile.data.current_revenue = REQUIRED;
  if (!values.getIn(['profile', 'data', 'total_revenue'])) errors.profile.data.total_revenue = REQUIRED;
  if (!values.getIn(['profile', 'data', 'kpis'])) errors.profile.data.kpis = REQUIRED;
  if (!values.getIn(['profile', 'data', 'traction_growth'])) errors.profile.data.traction_growth = REQUIRED;
  if (values.getIn(['profile', 'data', 'traction_growth']) && values.getIn(['profile', 'data', 'traction_growth']).length > 300) {
    errors.profile.data.traction_growth = 'Exceeds 300 character limit';
  }

  if (!values.getIn(['profile', 'data', 'burn_rate'])) errors.profile.data.burn_rate = REQUIRED;
  if (!values.getIn(['profile', 'data', 'previous_capital'])) errors.profile.data.previous_capital = REQUIRED;
  if (!values.getIn(['profile', 'data', 'future_raise'])) errors.profile.data.future_raise = REQUIRED;
  if (!values.getIn(['profile', 'data', 'funding_sources'])) errors.profile.data.funding_sources = REQUIRED;
  if (!values.getIn(['profile', 'data', 'capital_allocation'])) errors.profile.data.capital_allocation = REQUIRED;

  return errors;
};

export default validate;
