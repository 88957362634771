import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const VinettaStyles = styled.div`
  font-size: ${(props) => props.theme.text.fontSize}px;
  line-height: ${(props) => props.theme.text.lineHeight}px;
  color: ${(props) => props.theme.color.text};
  margin-top: 90px;
  section {
    padding: 50px 0;
  }
  .primary {
    color: ${(props) => props.theme.color.primary};
    h1, h2, h3 {
      color: ${(props) => props.theme.color.primary};
    }
  }
  .primaryBg {
    background: ${(props) => props.theme.color.primary};
    h1, h2, h3 {
      color: ${(props) => props.theme.color.light};
    }
  }
  .secondary {
    color: ${(props) => props.theme.color.secondary};
    h1, h2, h3 {
      color: ${(props) => props.theme.color.secondary};
    }
  }
  .secondaryBg {
    background: ${(props) => props.theme.color.secondary};
    h1, h2, h3 {
      color: ${(props) => props.theme.color.light};
    }
  }
  .white {
    color: #fff;
  }
  .center {
    text-align: center;
  }
  h1, h2, h3 {
    margin: 0px;
    color: ${(props) => props.theme.color.dark};
    line-height: 1em;
  }
  h1 {
    font-weight: lighter;
    text-transform: uppercase;
    font-size: ${(props) => props.theme.text.fontSize * 1.6}px;
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 3}px;
    `}
    ${breakpoint('md')`
      font-size: ${(props) => props.theme.text.fontSize * 4}px;
    `}
    ${breakpoint('lg')`
      font-size: ${(props) => props.theme.text.fontSize * 5}px;
    `}
  }
  h2 {
    font-size: ${(props) => props.theme.text.fontSize * 1.4}px;
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 2.2}px;
    `}
  }
  h3 {
    font-size: ${(props) => props.theme.text.fontSize * 1.2}px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 500;
    margin-bottom: 0.5em;
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 1.5}px;
    `}
  }
  h4 {
    text-transform: uppercase;
    letter-spacing: .1em;
    margin: 0px;
    font-size: ${(props) => props.theme.text.fontSize * 0.9}px;
    line-height: 1.2em;
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 1.3}px;
    `}
  }
  h5 {
    font-size: 24px;
    font-weight: normal;
    color: #000;
    margin: 0px;
    line-height: 120%;
  }
  small p {
    font-size: ${(props) => props.theme.text.fontSize * 0.6}px;
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 0.7}px;
    `}
  }
  p {
    font-size: ${(props) => props.theme.text.fontSize * 0.7}px;
    line-height: 1.6em;
    margin: 1em 0px;
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 1}px;
    `}
  }
  hr {
    border-bottom: solid 1px ${(props) => props.theme.color.secondary};
  }
  .thumb {
    padding: 40px 0%;
    text-align: center;
    .img {
      margin-bottom: 20px;
    }
    p, small {
      display: block;
      font-size: .6em;
      line-height: 1.4em;
      padding-top: 10px;
      font-weight: lighter;
    }
  }
  .stats {
    h1 {
      font-size: ${(props) => props.theme.text.fontSize * 4}px;
      margin-bottom: 20px;
    }
    h5 {
      margin-bottom: -15px;
    }
  }
`;

export default VinettaStyles;
