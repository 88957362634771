/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Our basic button
 * @type {StyledComponentClass<JSX.IntrinsicElements["button"], any, P>}
 */
export const ButtonStyles = styled.div`
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  color: ${(props) => props.color || props.theme.color.primary};
  border: solid 3px ${(props) => (props.borderless ? 'transparent' : (props) => props.color || props.theme.color.primary)};
  padding: ${(props) => props.theme.padding[props.size || 'base']}px ${(props) => props.theme.padding[props.size || 'base'] * 4}px;
  border-radius: ${(props) => props.theme.padding.large * 2}px;
  box-sizing: border-box;
  background-color: ${(props) => props.background || 'transparent'};
  transform: scale(${(props) => props.scale || '1'});
  font-weight: bold;
  font-size: ${(props) => props.size === 'small' ? props.theme.text.fontSize * 0.6 : props.theme.text.fontSize * 0.8}px;
  width: ${(props) => props.width || 'auto'};
  &:hover {
    color: ${(props) => props.theme.color.dark};
    border: solid 3px ${(props) => props.theme.color.dark};
    text-decoration: none;
    cursor: pointer;
  }
  &:active {
    color: ${(props) => props.theme.color.secondary};
    border: solid 3px ${(props) => props.theme.color.secondary};
    text-decoration: none;
    cursor: pointer;
  }
`;

ButtonStyles.propTypes = {
  /** Render the 'primary' style */
  primary: PropTypes.bool,
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'text', 'success', 'info', 'danger', 'warning']),
};
ButtonStyles.defaultProps = {
  primary: false,
  children: ' ',
};

const Button = ({ link, target, ...props }) =>
  link && (link.startsWith('http') || link.startsWith('mailto')) ? (
    <a className="button" href={link} target={target}>
      <ButtonStyles {...props} />
    </a>
  ) : (
    <Link className="button" to={link} target={target}>
      <ButtonStyles {...props} />
    </Link>
  );

Button.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
};
Button.defaultProps = {
  link: '/',
  target: '_self',
};

/** @component */
export default Button;
