import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const EventsUpcomingStyles = styled.div`
  a {
    color: ${(props) => props.theme.color.dark};
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  .thumb,
  .carousel-container {
    ${breakpoint('sm')`
      padding-left: 20px;
      padding-right: 20px;
    `}
  }
  .overlay {
    ${breakpoint('sm')`
      width: 100%;
      left: 0;
    `}
  }
  .carousel-container {
    height: 450px;
    overflow: hidden;
  }
  .carousel {
    position: relative;
    z-index: 0;
  }
  .carousel__slider {
    z-index: 1;
  }
  .carousel__back-button,
  .carousel__next-button {
    position: absolute;
    outline: none;
    z-index: 2;
    top: 155px;
    background-color: ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.light};
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 100%;
    i {
      font-size: 36px;
    }
    &:disabled {
      opacity: 0.4;
    }
  }
  .carousel__next-button {
    right: -14px;
  }
  .carousel__back-button {
    left: -14px;
  }
  .overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    left: 0;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.75);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    ${breakpoint('sm')`
      width: calc(100% - 40px);
      left: 20px;
    `}
  }
  .thumb:hover .overlay {
    opacity: 1;
  }
  .overlay-inner {
    padding: 0 20px;
    > .chapter {
      color: ${(props) => props.theme.color.primary};
      font-size: 13px;
    }
    > .event-name {
      color: ${(props) => props.theme.color.light};
      font-size: ${(props) => props.theme.text.fontSize}px;
    }
    > p {
      color: ${(props) => props.theme.color.light};
      font-size: 15px;
      margin: 0;
      padding-top: 4px;
    }
    > .cta {
      transform: scale(0.7);
      div {
        color: ${(props) => props.theme.color.light};
        &:hover {
          border-color: ${(props) => props.theme.color.light};
        }
      }
    }
  }
`;

export default EventsUpcomingStyles;
