import styled from 'styled-components';

const TeamStyles = styled.div`
  .thumb {
    padding: 0px;
    p {
      height: 50px;
    }
    .overlay {
      opacity: 0;
      background: rgba(255, 112, 98, 0.9);
      color: #fff;
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      top: 0px;
      text-decoration: none;
    }
    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }
`;

export default TeamStyles;
