import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import TestimonialsStyles from './testimonials_styles';
import FlamelinkImage from '../../../util/flamelink_image';
import 'pure-react-carousel/dist/react-carousel.es.css';

const propTypes = {
  testimonials: PropTypes.instanceOf(Map),
};

const defaultProps = {
  testimonials: new Map(),
};

const TestimonialsView = ({ testimonials }) => (
  <TestimonialsStyles>
    <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={testimonials.size} isPlaying interval={8000}>
      <Slider>
        {testimonials
          .sortBy((testimonial) => testimonial.get('order'))
          .valueSeq()
          .map((testimonial, index) => (
            <Slide key={testimonial.get('attributionName')} index={index}>
              <div className="testimonial container">
                <blockquote>{testimonial.get('quote')}</blockquote>
                <figure>
                  {testimonial.get('avatar') && <FlamelinkImage id={testimonial.get('avatar').first()} />}
                  <figcaption>
                    <strong>
                      &mdash;
                      {testimonial.get('attributionName')}
                    </strong>
                    <p>{testimonial.get('attributionDescription')}</p>
                  </figcaption>
                </figure>
              </div>
            </Slide>
          ))}
      </Slider>
      <DotGroup />
    </CarouselProvider>
  </TestimonialsStyles>
);

TestimonialsView.propTypes = propTypes;
TestimonialsView.defaultProps = defaultProps;

export default TestimonialsView;
