import posed from 'react-pose';

export const FadeIn = posed.div({
  visible: {
    opacity: 1,
    delay: 500,
    transition: { duration: 1000 },
  },
  hidden: {
    opacity: 0,
  },
});

export const FallIn = posed.div({
  visible: {
    y: '0%',
    opacity: 1,
    delay: 700,
    transition: { duration: 500, type: 'spring' },
  },
  hidden: {
    y: '-100%',
    opacity: 0,
  },
});

export const FadeInUp = posed.div({
  visible: {
    y: '0%',
    opacity: 1,
    delay: 500,
    transition: { duration: 500, type: 'spring' },
  },
  hidden: {
    y: '20%',
    opacity: 0,
  },
});

export const OpenUp = posed.div({
  visible: {
    scaleY: 1,
    transition: { duration: 500, type: 'spring' },
  },
  hidden: {
    scaleY: 0,
  },
});

export default {};
