import { connect } from 'react-redux';
import NavTopView from './nav_top_view';
import { getCurrentRoute, getTitle } from '../../../reducer';
import { toggleDrawer } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(dispatch, { prop })
const mapStateToProps = (state) => ({
  // prop assignments
  path: getCurrentRoute(state),
  title: getTitle(state),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = (dispatch) => ({
  // action creator assignments
  toggleDrawer: () => dispatch(toggleDrawer()),
});

const NavTopContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavTopView);

export default NavTopContainer;
