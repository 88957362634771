import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ChaptersFilterStyles from './chapters_filter_styles';

const propTypes = {
  chapters: PropTypes.instanceOf(List),
  setFilterChapter: PropTypes.func.isRequired,
  filter: PropTypes.string,
};

const defaultProps = {
  chapters: new List(),
  filter: null,
};

const ChaptersFilterView = ({ chapters, setFilterChapter, filter }) => (
  <ChaptersFilterStyles>
    <ul>
      <li>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={filter ? '' : 'active'} onClick={() => setFilterChapter(null)}>
          All
        </a>
      </li>
      {chapters.valueSeq().map((chapter) => (
        <li key={chapter.get('slug')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={filter === chapter.get('id') ? 'active' : ''} onClick={() => setFilterChapter(chapter.get('id'))}>
            {chapter.get('city')}
          </a>
        </li>
      ))}
    </ul>
  </ChaptersFilterStyles>
);

ChaptersFilterView.propTypes = propTypes;
ChaptersFilterView.defaultProps = defaultProps;

export default ChaptersFilterView;
