import React from 'react';
import PropTypes from 'prop-types';
import { fl } from '../vendor/flamelink';

export default class FlamelinkImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }

  componentDidMount() {
    const { id } = this.props;
    this.getFlameLinkImage(id);
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.props;
    if (nextProps.id !== id) {
      this.getFlameLinkImage(nextProps.id);
    }
  }

  getFlameLinkImage(id) {
    fl.storage.getURL(id).then((url) => {
      this.setState({ url });
    });
  }

  render() {
    const { width, height, style, children } = this.props;
    const { url } = this.state;
    if (!url) return null;
    return (
      <div
        className="img"
        style={{
          display: 'inline-block',
          backgroundColor: '#eee',
          backgroundRepeat: 'no-repeat',
          backgroundImage: url && `url('${url.replace("'", "%27")}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height,
          width,
          ...style,
        }}
      >
        {children}
      </div>
    );
  }
}

FlamelinkImage.propTypes = {
  id: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

FlamelinkImage.defaultProps = {
  id: undefined,
  width: undefined,
  height: undefined,
  style: {},
  children: undefined,
};
