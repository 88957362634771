import React from 'react';
import PropTypes from 'prop-types';
import PitchAndPanelStyles from './pitch_and_panel_styles';
import theme from '../../../common/theme';
import Button from '../../../common/components/button';
import Snippet from '../snippet';
import Hero from '../hero';
import { PageMetaHead } from '../../../util/meta_head';

const propTypes = {
  page: PropTypes.shape({}),
};

const defaultProps = {
  page: new Map(),
};

const PitchAndPanelView = ({ page }) => (
  <PitchAndPanelStyles>
    <PageMetaHead page={page} />
    {page && page.get('hero') && (
      <Hero background={page.get('heroBackground').first()}>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.get('hero') }} />
        </section>
      </Hero>
    )}
    <div className="container">
      <section>
        <h3>Showcase</h3>
        <h1 className="primary">Pitch</h1>
        <h1 className="secondary">&amp; Panel</h1>
        <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        {page.get('ctaLink') && (
          <div style={{ textAlign: 'center' }}>
            <Button link={page.get('ctaLink')} target="_blank">
              {page.get('ctaText')}
            </Button>
          </div>
        )}
      </section>
      <section>
        <h2 className="primary">The Program</h2>
        <Snippet slug="pitch-and-panel-program" />

        <div className="row">
          {page.get('snippets') && page.get('snippets').map((id, idx) => (
            <div key={id} className="pitch-and-panel-program-item col-md-4 col-sm-6">
              <Snippet id={id} imageHeight={idx % 2 === 0 ? '40px' : '200px'} imageWidth={idx % 2 === 0 ? '40px' : '100%'} />
            </div>
          ))}
        </div>
      </section>
    </div>

    <div className="container">
      <section>
        <h2 className="primary">Results</h2>
        <Snippet slug="pitch-and-panel-results" />

        <div className="row pitch-and-panel-results-companies">
          <div className="col-md-2">
            <Snippet slug="pitch-and-panel-results-company-1" imageWidth="100px" imageHeight="100px" />
          </div>
          <div className="col-md-2">
            <Snippet slug="pitch-and-panel-results-company-2" imageWidth="100px" imageHeight="100px" />
          </div>
          <div className="col-md-2">
            <Snippet slug="pitch-and-panel-results-company-3" imageWidth="100px" imageHeight="100px" />
          </div>
          <div className="col-md-2">
            <Snippet slug="pitch-and-panel-results-company-4" imageWidth="100px" imageHeight="100px" />
          </div>
          <div className="col-md-2">
            <Snippet slug="pitch-and-panel-results-company-5" imageWidth="100px" imageHeight="100px" />
          </div>
          <div className="col-md-2">
            <Snippet slug="pitch-and-panel-results-company-6" imageWidth="100px" imageHeight="100px" />
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-md-6">
            <h2 className="primary">Expectations</h2>
            <Snippet slug="pitch-and-panel-expectations" />
          </div>
          <div className="col-md-6">
            <h2 className="primary">Eligibility</h2>
            <Snippet slug="pitch-and-panel-eligibility" />
          </div>
        </div>
      </section>
      {page.get('ctaLink') && (
        <section>
          <div style={{ textAlign: 'center' }}>
            <Button link={page.get('ctaLink')} background={theme.color.primary} color="#fff" target="_blank">
              {page.get('ctaText')}
            </Button>
          </div>
        </section>
      )}
    </div>
  </PitchAndPanelStyles>
);

PitchAndPanelView.propTypes = propTypes;
PitchAndPanelView.defaultProps = defaultProps;

export default PitchAndPanelView;
