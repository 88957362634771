import styled from 'styled-components';

const FooterStyles = styled.div`
  background: ${(props) => props.theme.color.secondary};
  padding: ${(props) => props.theme.padding.large * 4}px 0;
  margin-top: ${(props) => props.theme.padding.large * 2}px;
  text-align: center;

  .logo {
    display: flex;
    flex-direction: column;
    img:first-child {
      transform: scale(0.7);
      margin-bottom: 10px;
    }
  }

  .join-links:before {
    content: '';
    display: block;
    height: 40px;
    width: 40px;
    background-color: transparent;
    position: absolute;
    top: -20px;
    left: 100px;
    border-right: 20px solid ${(props) => props.theme.color.primary};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  .join-links {
    position: relative;
    background: ${(props) => props.theme.color.primary};
    border-radius: 40px;
    padding: 10px;
    display: inline-block;
    div {
      display: inline-block;
      margin: 0px 5px;
      background: #fff;
      padding: 10px 30px;
      border-radius: 30px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
    }
    a {
      color: ${(props) => props.theme.color.primary};
      &:hover {
        text-decoration: none;
        color: ${(props) => props.theme.color.secondary};
      }
    }
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 60px 0;
    li {
      display: inline-block;
      margin: 0 20px 10px;
    }
    a.btn {
      border-color: #fff;
      color: #fff;
      &:hover {
        text-decoration: none;
        color: #000;
        border-color: #000;
      }
    }
    &.links {
      a {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.2em;
        &:hover {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
  .cta-buttons .social-container {
    a {
      display: inline-block;
      margin: 0 20px;
      svg {
        width: 36px;
        height: 36px;
        transition: transform 0.3s;
        path {
          transition: fill 0.3s;
        }
      }
      &:hover {
        svg {
          transform: scale(1.1);
        }
        svg path {
          fill: ${(props) => props.theme.color.dark};
        }
      }
    }
    }
`;

export default FooterStyles;
