import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const CoreStrategyFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Core Strategy</h4>

      <div className="col-sm-12">
        <Field
          component={TextField}
          label="Tell us what your company does in one sentence?"
          name="profile['data']['company_description']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['customer_problem']"
          component={TextField}
          label="What is the customer problem and how does your company solve it?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['revenue']"
          component={TextField}
          label="How do you make money? "
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['go_to_market']"
          component={TextField}
          label="What is your Go to Market (GTM) strategy?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

CoreStrategyFields.propTypes = propTypes;
CoreStrategyFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CoreStrategyFields);
