import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { DropzoneWrapper } from '../styles/form';

const FileUpload = ({
  input,
  onChange,
  multiple = false,
  accept = 'text/plain, application/vnd.ms-excel, text/csv',
  text,
  label,
  meta: { error },
  ...rest
}) => {
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const handleChange = (fs, rfs) => {
    setFiles(fs);
    setRejectedFiles(rfs);

    if (input) {
      input.onChange(fs);
    } else if (onChange) {
      onChange(fs);
    } else {
      console.warn('redux-form-dropzone => Forgot to pass onChange props ?');
    }
  };

  return (
    <DropzoneWrapper>
      <h5>
        {label}
      </h5>
      <Dropzone
        {...rest}
        className={`dropzone with-border ${error ? 'is-invalid' : ''}`}
        activeClassName="dropzone-active"
        rejectClassName="dropzone-reject"
        accept={accept}
        onDrop={(accepted, rejected) => handleChange(accepted, rejected)}
        multiple={multiple}
      >
        <small>{text}</small>
      </Dropzone>

      {
        files && files.length ? (
          <small>
            Accepted:&nbsp;
            { files.map((file) => <span key={file.name} style={{ display: 'block' }}>{file.name}</span>) }
          </small>
        ) : ''
      }
      {
        rejectedFiles && rejectedFiles.length ? (
          <small>
            Rejected:
            { rejectedFiles.map((file) => <span key={file.name} style={{ display: 'block' }}>{file.name}</span>) }
          </small>
        ) : ''
      }
    </DropzoneWrapper>
  );
};

FileUpload.propTypes = {
  input: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.shape({}),
};

FileUpload.defaultProps = {
  onChange: undefined,
  multiple: false,
  accept: 'image/*',
  text: undefined,
  label: 'File Upload',
  meta: {},
};

export default FileUpload;
