import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Button from '../common/components/button';
import GalleryStyles from './gallery_styles';
import { fl } from '../vendor/flamelink';

// const DEFAULT_CONTENT = new Map();

const GalleryImage = ({ photo, orientation }) => (
  <div
    className="gallery-image-container"
    style={{ height: orientation === 'landscape' ? '173px' : '270px', backgroundImage: `url(${photo.src})` }}
  >
    <div className="overlay" style={{ height: orientation === 'landscape' ? '173px' : '270px' }}>
      <div className="overlay-inner">
        <div className="gallery-image-location">{photo['data-location']}</div>
        <div className="gallery-image-title">{photo.title}</div>
        <div className="gallery-image-date">{moment(photo['data-date']).format('MMMM Do, YYYY')}</div>
        <Button size="small" link={photo['data-url']} target="_blank">
          View Gallery
        </Button>
      </div>
    </div>
  </div>
);

// eslint-disable-next-line react/no-multi-comp
class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    const { gallery, chapters } = this.props;
    const assemblePreload = (gallery.sortBy((item) => item.get('date')).reverse().toArray() || []).map((item, i) => {
      const [key, photo] = item;
      const location = chapters && chapters.size && chapters.filter((chapter) => parseInt(chapter.get('id'), 10) === photo.get('chapter')).first();
      const results = fl.storage.getURL(i % 2 === 0 ? photo.get('portraitImage').first() : photo.get('landscapeImage').first()).then((src) => {
        const photoMeta = new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            resolve({
              src,
              id: key,
              title: photo.get('title'),
              'data-date': photo.get('date'),
              'data-url': photo.get('url'),
              'data-location': location && location.get('city'),
            });
          };
        });
        return photoMeta;
      });
      return results;
    });
    Promise.all(assemblePreload).then((results) => {
      this.setState({ images: results });
    });
  }

  render() {
    const { images } = this.state;

    return (
      <GalleryStyles>
        <div className="gallery-container row">
          <div className="gallery-column col-md-4">
            {images[0] && <GalleryImage photo={images[0]} orientation="portrait" />}
            {images[4] && <GalleryImage photo={images[4]} orientation="portrait" />}
          </div>
          <div className="gallery-column col-md-4">
            {images[1] && <GalleryImage photo={images[1]} orientation="landscape" />}
            {images[3] && <GalleryImage photo={images[3]} orientation="landscape" />}
            {images[5] && <GalleryImage photo={images[5]} orientation="landscape" />}
          </div>
          <div className="gallery-column col-md-4">
            {images[2] && <GalleryImage photo={images[2]} orientation="portrait" />}
            {images[6] && <GalleryImage photo={images[6]} orientation="portrait" />}
          </div>
        </div>
      </GalleryStyles>
    );
  }
}

GalleryImage.propTypes = {
  photo: PropTypes.shape({}).isRequired,
  orientation: PropTypes.string.isRequired,
};

Gallery.propTypes = {
  gallery: PropTypes.shape({}).isRequired,
  chapters: PropTypes.shape({}).isRequired,
};

export default Gallery;
