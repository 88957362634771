import styled from 'styled-components';

const AboutStyles = styled.div`
  .join-links:before {
    top: -20px;
    left: 260px;
  }
  .gender-icons {
    padding-top: 50px;
    text-align: center;
  }
  .infographics {
    .infographic {
      padding: 20px 0;
      h5 {
        text-align: center;
      }
      p {
        font-size: 18px;
      }
      .image-container {
        text-align: center;
        padding: 20px 0;
      }
    }
  }
  .pillars {
    .pillar {
      height: 430px;
      overflow: hidden;
      svg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      h5 {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 10px;
        box-sizing: border-box;
        width: 130px;
        text-align: center;
        font-size: 0.7em;
        display: flex;
        height: 130px;
        align-items: center;
      }
      .snippet-text {
        display: flex;
        align-items: center;
        width: 100px;
        height: 110px;
        margin: 180px auto 0;
        position: relative;
        z-index: 2;
        > small {
          text-align: center;
          width: 100%;
        }
        p {
          font-size: 0.7em;
          line-height: 110%;
        }
      }
    }
  }
`;

export default AboutStyles;
