import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'red',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 1000,
    },
  },
};

class MultiSelect extends React.Component {
  state = {
    name: [],
  };

  handleChange = (event) => {
    const { input } = this.props;
    const { value } = event.target;

    this.setState({ name: value });
    input.onChange(value);
  };

  render() {
    const { options, label, fullWidth } = this.props;
    const { name } = this.state;

    return (
      <FormControl fullWidth={fullWidth}>
        <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
        <Select
          multiple
          value={name}
          onChange={this.handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {options.map((n) => (
            <MenuItem key={n} value={n}>
              {n}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

MultiSelect.propTypes = {
  input: PropTypes.shape({}),
  options: PropTypes.shape([]).isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
};

MultiSelect.defaultProps = {
  input: undefined,
  label: undefined,
  fullWidth: true,
};

export default withStyles(styles, { withTheme: true })(MultiSelect);
