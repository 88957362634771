// # Partnerships

// - Do you have any partnerships in place that have played a big role in making your business successful?
//   -Example: Partnership with Macy's or Walmart to boost sales and an increase in users.

// - if yes!
//   b. Please list names of companies you have partnered with (Max 75 characters). *
//   Examples: Walmart, Amazon, Cisco

//   c. Out of all of your company partnerships, please explain the most complex partnership you have negotiated. *
//   Example: Negotiated an integration with Hostels.com to allow customers access to our database of crowdsourced travel itineraries.

import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const PartnershipFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Partnerships</h4>

      <div className="col-sm-12">
        <Field
          component={TextField}
          label="Do you have any partnerships in place that have played a big role in making your business successful?"
          name="profile['data']['partnerships']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <small>If yes:</small>
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['partnership_names']"
          component={TextField}
          label="Please list names of companies you have partnered with (Max 75 characters)."
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['partnership_negotiation']"
          component={TextField}
          label="Out of all of your company partnerships, please explain the most complex partnership you have negotiated."
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

PartnershipFields.propTypes = propTypes;
PartnershipFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(PartnershipFields);
