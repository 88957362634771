import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import titleize from 'titleize';
import NavTopStyles, { PageTitle } from './nav_top_styles';

const propTypes = {
  title: PropTypes.string,
  toggleDrawer: PropTypes.func,
  children: PropTypes.node,
  path: PropTypes.string,
};

const defaultProps = {
  title: undefined,
  toggleDrawer: () => {},
  children: undefined,
  path: '',
};

const NavTopView = ({ title, toggleDrawer, children, path }) => (
  <NavTopStyles path={path}>
    <i className="material-icons" onClick={() => toggleDrawer()}>
      menu
    </i>
    <PageTitle>
      {title ||
       titleize(path
         .split('/')
         .slice(-1)[0]
         .split(/[_-]/)
         .join(' ') || '',
       )}
    </PageTitle>
    {path !== '/' && (
      <Link className="logo" to="/">
        <img src="/logo-nav.svg" alt="Vinetta Logo" />
      </Link>
    )}
    {children}
  </NavTopStyles>
);

NavTopView.propTypes = propTypes;
NavTopView.defaultProps = defaultProps;

export default NavTopView;
