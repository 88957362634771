import styled from 'styled-components';

const ChaptersFilterStyles = styled.div`
  ul {
    list-style: none;
    padding: 0px;
    margin-bottom: 20px;
    li {
      display: inline-block;
      margin: 0px 20px;
      a {
        color: #000;
        font-size: 18px;
        &:hover {
          cursor: pointer;
        }
        &.active {
          color: ${(props) => props.theme.color.primary};
        }
      }
    }
  }
`;

export default ChaptersFilterStyles;
