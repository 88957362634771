import React, { useRef, useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import Typed from 'typed.js';
import Testimonials from '../testimonials';
import Statistics from '../statistics';
import EventsUpcoming from '../events_upcoming';
import HomeStyles from './home_styles';
import Hero from '../hero';
import JoinUs from '../join_us';
import MetaHead from '../../../util/meta_head';

const HeroPose = posed.div({
  visible: { staggerChildren: 300, delayChildren: 200 },
  hidden: {},
});

const HeroWord = posed.h1({
  visible: { y: '0%', opacity: 1, transition: { duration: 500, type: 'spring' } },
  hidden: { y: '-100%', opacity: 0 },
});
const HeroImage = posed.img({
  visible: {
    y: '0%',
    opacity: 1,
    delay: 500,
    transition: { duration: 500, type: 'spring' },
  },
  hidden: { y: '-100%', opacity: 0 },
});

const propTypes = {
  page: PropTypes.shape({}),
};

const defaultProps = {
  page: new Map(),
};

const HomeView = ({ page }) => {
  const typedElement = useRef(null);
  useEffect(() => {
    if (!typedElement.current) {
      return () => {};
    }
    const strings = ['Financial', 'Intellectual', 'Personal', 'Social'];
    const typed = new Typed(typedElement.current, {
      strings,
      typeSpeed: 50,
      showCursor: false,
      loop: true,
    });
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <HomeStyles>
      <MetaHead />
      <Hero background={page.get('heroBackground') && page.get('heroBackground').first()}>
        <div className="huge">
          <HeroPose initialPose="hidden" pose={page.get('title') ? 'visible' : 'hidden'}>
            <HeroImage className="wordmark" src="/logo-wordmark.svg" />
            {page.get('title') &&
              page
                .get('title')
                .split(' ')
                .map((word) => <HeroWord key={word}>{word}</HeroWord>)}
            <HeroImage className="logo" src="/logo-mark.svg" />
          </HeroPose>
        </div>
      </Hero>

      <div className="container">
        {/* Capital to Conquor */}
        <section>
          <h3>Unleashing female drive</h3>
          <h1 className="primary">
            <span ref={typedElement} />
            &nbsp;
          </h1>
          <h1 className="secondary">Capital</h1>
          <div dangerouslySetInnerHTML={{ __html: page.get('body') }} />
        </section>
        <section>
          <h3>Join Us</h3>
          <JoinUs />
        </section>
      </div>

      {/* Testimonials */}
      <section style={{ padding: 0 }}>
        <Testimonials category="home" />
      </section>

      <div className="container">
        {/* Statistics */}
        <section style={{ padding: 0 }}>
          <Statistics />
        </section>

        {/* Upcoming Events */}
        <section style={{ padding: 0 }}>
          <EventsUpcoming />
        </section>
      </div>
    </HomeStyles>
  );
};

HomeView.propTypes = propTypes;
HomeView.defaultProps = defaultProps;

export default HomeView;
