import React from 'react';
import PropTypes from 'prop-types';
import ThumbStyles from './thumb_styles';
import FlamelinkImage from '../../../util/flamelink_image';

const propTypes = {
  leader: PropTypes.instanceOf(Map),
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

const defaultProps = {
  leader: undefined,
};

const ThumbView = ({ leader, height, width }) =>
  leader && leader.get('lastName') ? (
    <ThumbStyles>
      <div className="thumb">
        <a href={leader.get('linkedInLink')} target="_blank" rel="noopener noreferrer">
          {leader.getIn(['image', '0']) && <FlamelinkImage id={leader.getIn(['image', '0'])} width={width || '100%'} height={height || '200px'} />}
        </a>
        <a
          className="overlay"
          style={{ width: width || 'calc(100% - 30px)', height: height || '200px' }}
          href={leader.get('linkedInLink')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>
            <b>{`${leader.get('firstName')} ${leader.get('lastName')}`}</b>
            <br />
            {`${leader.get('jobTitle')}, ${leader.get('company')}`}
          </p>
        </a>
      </div>
    </ThumbStyles>
  ) : null;

ThumbView.propTypes = propTypes;
ThumbView.defaultProps = defaultProps;

export default ThumbView;
