import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  FormSelect,
  reduxForm,
  MultiSelect,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const YES_NO_OPTIONS = [
  ['yes', 'Yes'],
  ['no', 'No'],
];

const FUNDING_SOURCE_OPTIONS = [
  'Grants',
  'Bootstrap/Personal Investment',
  'Friends/Family',
  'Crowdfunding',
  'Pitch Competition Winnings',
  'Angel Investors',
  'Accelerator/Incubator Investments',
  'Venture Capital',
  'Founder-Funded Revenue',
  'Other',
];

const CAPITAL_ALLOCATION_OPTIONS = [
  'Marketing',
  'Hiring Additional Employees',
  'Product Improvement',
  'User Aquisition',
  'Research and Development',
  'Other',
];

const FundraiseFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Fundraise</h4>

      <div className="col-sm-12">
        <Field
          component={FormSelect}
          fullWidth
          label="Have you previously raised capital?"
          name="profile['data']['previous_capital']"
          options={YES_NO_OPTIONS}
        />
      </div>

      <div className="col-sm-12">
        <Field
          component={MultiSelect}
          name="profile['data']['funding_sources']"
          options={FUNDING_SOURCE_OPTIONS}
          label="What are your current funding sources?"
        />
      </div>

      <div className="col-sm-12">
        <Field
          component={TextField}
          fullWidth
          label="If you are currently raising (or plan to raise in the next 6 months), please list the target amount."
          name="profile['data']['future_raise']"
        />
      </div>

      <div className="col-sm-12">
        <Field
          component={MultiSelect}
          fullWidth
          label="If raising, how will you allocate your capital?"
          name="profile['data']['capital_allocation']"
          options={CAPITAL_ALLOCATION_OPTIONS}
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

FundraiseFields.propTypes = propTypes;
FundraiseFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(FundraiseFields);
