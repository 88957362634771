import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const NavTopStyles = styled.div`
  background: ${(props) => (props.path !== '/' ? props.theme.color.primary : 'transparent')};
  padding: ${(props) => props.theme.padding.large}px;
  position: fixed;
  top: 0;
  width: calc(100vw);
  z-index: 4;
  .logo {
    display: block;
    float: left;
    transform: scale(0.6);
    transform-origin: left;
    ${breakpoint('sm')`
      transform: scale(1);
    `}
  }
  i {
    font-size: 35px;
    margin-top: 10px;
    margin-right: 14px;
    color: ${(props) => props.theme.color.secondary};
    float: right;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.color.light};
    }
    &:active {
      color: ${(props) => props.theme.color.dark};
    }
  }
`;

export const PageTitle = styled.div`
  float: right;
  font-size: 0.65em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 10px;
  margin-right: 20px;
  color: #000;
  @media screen and (max-width: 560px) {
    display: none;
  }
`;

export default NavTopStyles;
