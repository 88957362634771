import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldTheme, MuiThemeProvider } from '../../../common/form';
import AngelistFormStyles from './angelist_form_styles';

import IntroPage from './pages/intro_page';
import CompanyDescriptionField from './pages/company_description_field';
import BackgroundInformationFields from './pages/background_information_fields';
import CompanyInformationFields from './pages/company_information_fields';
import CoreStrategyFields from './pages/core_strategy_fields';
import TeamFields from './pages/team_fields';
import CompetitionFields from './pages/competition_fields';
import PartnershipFields from './pages/partnership_fields';
import TractionFields from './pages/traction_fields';
import FundraiseFields from './pages/fundraise_fields';
import AttachmentFields from './pages/attachment_fields';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
};

class AngelistFormView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  nextPage = () => {
    const { page } = this.state;

    this.setState({ page: page + 1 });
  }

  previousPage = () => {
    const { page } = this.state;

    this.setState({ page: page - 1 });
  }

  render() {
    const { onSubmit } = this.props;
    const { page } = this.state;

    return (
      <AngelistFormStyles>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <MuiThemeProvider theme={FieldTheme}>
                { page === 1 && <IntroPage onSubmit={this.nextPage} /> }
                { page === 2 && <CompanyDescriptionField onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 3 && <BackgroundInformationFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 4 && <CompanyInformationFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 5 && <CoreStrategyFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 6 && <TeamFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 7 && <CompetitionFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 8 && <PartnershipFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 9 && <TractionFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 10 && <FundraiseFields onSubmit={this.nextPage} previousPage={this.previousPage} /> }
                { page === 11 && <AttachmentFields onSubmit={onSubmit} previousPage={this.previousPage} /> }
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </AngelistFormStyles>
    );
  }
}

AngelistFormView.propTypes = propTypes;
AngelistFormView.defaultProps = defaultProps;

export default AngelistFormView;
