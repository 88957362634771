/* eslint-disable react/prop-types */
import React from 'react';
import ReactSVG from 'react-svg';
import JoinUsStyles from './join_us_styles';
import Button from '../../../common/components/button';

const JoinUsView = () => (
  <JoinUsStyles>
    <section>
      <div className="row">
        <div className="col-sm-4 center">
          <ReactSVG src="/vector/woman.svg" />
          <Button size="small" link="/founder">
            I am a founder
          </Button>
        </div>
        <div className="col-sm-4 center">
          <ReactSVG src="/vector/growth-graph.svg" />
          <Button size="small" link="/investor">
            I&apos;m an investor
          </Button>
        </div>
        <div className="col-sm-4 center">
          <ReactSVG src="/vector/partner.svg" />
          <Button size="small" link="/corporate-partner">
            As a corporate partner
          </Button>
        </div>
      </div>
    </section>
  </JoinUsStyles>
);

export default JoinUsView;
