import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import NavDrawerStyles from './nav_drawer_styles';

const Sidebar = posed.nav({
  open: { x: '0px', opacity: 1, delayChildren: 500 },
  closed: { x: '350px', opacity: 0, delay: 300 },
});
const SidebarNav = posed.ul({
  open: { staggerChildren: 50, delayChildren: 50 },
  closed: { staggerChildren: 50, delay: 50 },
});
const SidebarItem = posed.li({
  open: { x: '0%', opacity: 1 },
  closed: { x: '350px', opacity: 0 },
});

const NavDrawerView = ({ hideDrawer, isOpen }) => (
  <Sidebar initialPose="closed" pose={isOpen ? 'open' : 'closed'}>
    <NavDrawerStyles>
      <i className="material-icons" onClick={() => hideDrawer()}>
        menu
      </i>
      <SidebarNav initialPose="closed" pose="open" className="large">
        <SidebarItem>
          <Link to="/about" onClick={hideDrawer}>
            ABOUT
          </Link>
        </SidebarItem>
        <SidebarItem>
          <Link to="/chapters" onClick={hideDrawer}>
            CHAPTERS
          </Link>
        </SidebarItem>
        <SidebarItem>
          <Link to="/programs" onClick={hideDrawer}>
            PROGRAMS
          </Link>
        </SidebarItem>
        <SidebarItem>
          <Link to="/services" onClick={hideDrawer}>
            SERVICES
          </Link>
        </SidebarItem>
        <SidebarItem>
          <Link to="/team" onClick={hideDrawer}>
            TEAM
          </Link>
        </SidebarItem>
        <SidebarItem>
          <Link to="/media" onClick={hideDrawer}>
            MEDIA
          </Link>
        </SidebarItem>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <SidebarItem>
          <span>JOIN US</span>
        </SidebarItem>
        <SidebarItem className="small">
          <Link to="/founder" onClick={hideDrawer}>
            FOUNDERS
          </Link>
        </SidebarItem>
        <SidebarItem className="small">
          <Link to="/investor" onClick={hideDrawer}>
            INVESTORS
          </Link>
        </SidebarItem>
        <SidebarItem className="small">
          <Link to="/corporate-partner" onClick={hideDrawer}>
            CORPORATE PARTNERS
          </Link>
        </SidebarItem>
      </SidebarNav>
    </NavDrawerStyles>
  </Sidebar>
);

NavDrawerView.propTypes = {
  hideDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
};

NavDrawerView.defaultProps = {
  hideDrawer: () => {},
  isOpen: false,
};

export default NavDrawerView;
