import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import VinettaStyles from './vinetta_styles';
import NavTop from './components/nav_top';
import NavDrawer from './components/nav_drawer';
import Footer from './components/footer';
import './vinetta.css';
// Pages
import Home from './components/home';
import About from './components/about';
import Team from './components/team';
import Programming from './components/programming';
import Chapters from './components/chapters';
import Chapter from './components/chapter';
import Page from './components/page';
import Event from './components/event';
import Media from './components/media';
import Founder from './components/founder';
import Investor from './components/investor';
import CorporatePartner from './components/corporate_partner';
import Partnership from './components/partnership';
import ProblemHacking from './components/problem_hacking';
import AngelistForm from './components/angelist_form';
import Bootcamp from './components/bootcamp';
import PitchAndPanel from './components/pitch_and_panel';

const propTypes = {
  showDrawer: PropTypes.bool,
};

const defaultProps = {
  showDrawer: false,
};

const VinettaView = ({ showDrawer }) => (
  <VinettaStyles>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      {/* <Route path="/leaders/:slug" component={Leader} /> */}
      {/* <Route path="/team/:slug" component={Leader} /> */}
      <Route path="/team" component={Team} />
      <Route path="/programs" component={Programming} />
      <Route path="/chapters/:slug" component={Chapter} />
      <Route path="/chapters" component={Chapters} />
      <Route path="/events/:slug" component={Event} />
      {/* <Route path="/events" component={Events} /> */}
      <Route path="/media" component={Media} />
      <Route path="/founder" component={Founder} />
      <Route path="/investor" component={Investor} />
      <Route path="/corporate-partner" component={CorporatePartner} />
      <Route path="/procter-and-gamble" component={Partnership} />
      <Route path="/jpmorgan" component={Partnership} />
      <Route path="/problem-hacking" component={ProblemHacking} />
      <Route path="/bootcamp" component={Bootcamp} />
      <Route path="/showcase-pitch-and-panel" component={PitchAndPanel} />
      <Route path="/angel-list-form" component={AngelistForm} />
      <Route path="/venture-challenge-dc" render={() => <Redirect to="/chapters/washington-dc" />} />
      <Route path="/chapter-dc" render={() => <Redirect to="/chapters/washington-dc" />} />
      <Route path="/chapter-dc-team" render={() => <Redirect to="/chapters/washington-dc" />} />

      <Route path="/:slug" component={Page} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
    <NavTop>
      <NavDrawer isOpen={showDrawer} />
    </NavTop>
    <Footer />
  </VinettaStyles>
);

VinettaView.propTypes = propTypes;
VinettaView.defaultProps = defaultProps;

export default VinettaView;
