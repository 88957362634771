// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const propTypes = {
  variable: PropTypes.instanceOf(Map),
  animate: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

const defaultProps = {
  variable: new Map(),
  animate: false,
  prefix: '',
  suffix: '',
};

class VariableView extends React.Component {
  render() {
    const { variable, animate, prefix, suffix } = this.props;

    return (
      <>
        {variable && !animate && variable.get('value')}
        {variable && animate && parseInt(variable.get('value'), 10) > 0 && (
          <VisibilitySensor onChange={(isVisible) => (isVisible ? this.myCountUp.start() : this.myCountUp.reset())}>
            <CountUp
              redraw
              separator=","
              prefix={prefix}
              suffix={suffix}
              start={0}
              end={parseInt(variable.get('value'), 10)}
              ref={(countUp) => { this.myCountUp = countUp; }}
            />
          </VisibilitySensor>
        )}
      </>
    );
  }
}

VariableView.propTypes = propTypes;
VariableView.defaultProps = defaultProps;

export default VariableView;
