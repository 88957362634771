import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const IntroPage = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <p>
      By completing this Founder Application you will be eligible for a variety of Capital Opportunities which we create and curate across North America,
      &nbsp;including our annual $20K Venture Challenge in NY & DC
    </p>
    <p>
      Learn more about our programs at&nbsp;
      <a
        href="https://vinettaproject.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        vinettaproject.com
      </a>
    </p>
    <button type="submit" className="btn btn-primary">
      Ready for Capital
    </button>
  </form>
);

IntroPage.propTypes = propTypes;
IntroPage.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(IntroPage);
