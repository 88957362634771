import styled from 'styled-components';
import HeroStyles from '../hero/hero_styles';

const EventStyles = styled.div`
  ${HeroStyles} h3 {
    color: #000;
  }
  .info-collage {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    > div {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: row;
      &:nth-child(2) {
        flex-direction: row-reverse;
      }
      .text,
      .img {
        height: 300px;
        width: 50%;
      }
      .text {
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
      }
      .text p {
        font-size: 0.7em;
      }
      @media screen and (min-width: 700px) {
        width: 33%;
        flex-direction: column;
        &:nth-child(2) {
          flex-direction: column-reverse;
        }
        .img,
        .text {
          width: 100%;
        }
      }
    }
  }
  .share {
    background: ${(props) => props.color || props.theme.color.secondary};
    color: #ffffff;
    margin-bottom: 3em;
    > div,
    > p {
      font-size: 0.6em;
      text-align: center;
      margin: 0;
      img {
        margin: 0 10px;
      }
    }
  }
  .body {
    h3 {
      font-size: 1.3em;
    }
    h4 {
      color: ${(props) => props.color || props.theme.color.primary};
    }
  }
  .eventbrite {
    background: ${(props) => props.color || props.theme.color.primary};
  }
  .participants {
    > div {
      padding-bottom: 60px;
    }
    .participant {
      width: 100%;
      padding-top: 100%;
      margin-top: 30px;
      height: 0;
      position: relative;
      overflow: hidden;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      > .img,
      > .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .text {
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 20px;
        font-size: 0.8em;
        line-height: 1.1em;
        color: #4a4a4a;
        background: ${(props) => props.color || props.theme.color.primary};
        strong {
          text-transform: uppercase;
        }
        p {
          font-size: 0.8em;
          line-height: 1.2em;
          margin: 5px 0 0;
        }
      }
      &:hover {
        .text {
          display: flex;
          cursor: default;
        }
      }
    }
  }

  .location-map {
    h3 {
      text-transform: none;
    }
    .text p {
      font-size: 0.8em;
    }
  }
`;

export default EventStyles;
