import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  FormSelect,
  MultiSelect,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const COMPANY_AGE_OPTIONS = [
  ['1', '1 year'],
  ['2', '2 years'],
  ['3', '3 years'],
  ['4', '4 years'],
  ['5', '5 years'],
  ['> 5', '> 5 years'],
];

const SECTOR_OPTIONS = [
  'AdTech',
  'Aerospace',
  'AgTech',
  'Artificial Intelligence/Robotics',
  'Big Data/Analytics',
  'BioTech',
  'Bitcoin',
  'Cloud Services',
  'Consumer Products',
  'Cybersecurity',
  'Drones',
  'E-commerce',
  'Education',
  'Fashion/Wearables',
  'FinTech',
  'Gaming',
  'Green Tech',
  'Hardware',
  'Health Tech',
  'Insurtech',
  'Internet',
  'Media/Content',
  'Music',
  'Network/Communications',
  'News',
  'Other',
  'Professional Services',
  'Real Estate',
  'SaaS',
  'Social/Lifestyle',
  'Sports',
  'Telecommunications',
  'Travel',
  'VR',
  'Wearables',
  'Other',
];

const CompanyInformationFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Company Information</h4>

      <div className="col-sm-12">
        <Field
          component={TextField}
          label="Company Name"
          name="profile['data']['company_name']"
          fullWidth
        />

        <Field
          component={FormSelect}
          label="Company Age"
          name="profile['data']['company_age']"
          options={COMPANY_AGE_OPTIONS}
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['website']"
          component={TextField}
          label="Company Website"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['sectors']"
          component={MultiSelect}
          options={SECTOR_OPTIONS}
          label="What sectors do you operate in?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

CompanyInformationFields.propTypes = propTypes;
CompanyInformationFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CompanyInformationFields);
