import { connect } from 'react-redux';
import ChaptersMapView from './chapters_map_view';
import { getContent, getMapChapter, getContentBySlug } from '../../../reducer';
import { setCurrentMapChapter, closeMapChapter } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(dispatch, { prop })
const mapStateToProps = (state) => ({
  // prop assignments
  chapters: getContent(state, 'chapters'),
  currentChapter: getContentBySlug(state, 'chapters', getMapChapter(state)),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = (dispatch) => ({
  // action creator assignments
  showChapter: (chapter) => {
    dispatch(closeMapChapter());
    window.setTimeout(() => dispatch(setCurrentMapChapter(chapter)), 100);
  },
  closeChapter: () => dispatch(closeMapChapter()),
});

const ChaptersMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChaptersMapView);

export default ChaptersMapContainer;
