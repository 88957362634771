import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  TextField,
  FormSelect,
  MultiSelect,
  reduxForm,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const EMPLOYEE_COUNT_OPTIONS = [
  ['1-2', '1-2'],
  ['2-5', '2-5'],
  ['5-10', '5-10'],
  ['> 15', 'More than 15'],
];

const DIVISION_OPTIONS = [
  'Marketing',
  'Business Development',
  'Operations',
  'Tech/Engineering',
  'Sales',
  'Design',
  'Product',
  'Finance/Accounting',
  'Legal/Compliance',
  'HR/Recruiting',
  'Other',
];

const TeamFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Team Information</h4>

      <div className="col-sm-12">
        <Field
          component={FormSelect}
          options={EMPLOYEE_COUNT_OPTIONS}
          label="How many full-time employees do you have?"
          name="profile['data']['employee_count']"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['divisions']"
          component={MultiSelect}
          options={DIVISION_OPTIONS}
          label="What divisions do your full-time employees work in?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['part_time_employee_count']"
          component={FormSelect}
          options={EMPLOYEE_COUNT_OPTIONS}
          label="How many part-time employees or contractors do you have?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['part_time_divisions']"
          component={MultiSelect}
          options={DIVISION_OPTIONS}
          label="What divisions do your part-time employees or contractors work in?"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['qualification']"
          component={TextField}
          label="What makes your team uniquely qualified to build this company? (Maximum 100 characters)"
          fullWidth
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </form>
);

TeamFields.propTypes = propTypes;
TeamFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(TeamFields);
