import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  Field,
  TextField,
  FileUpload,
} from '../../../../common/form';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

const defaultProps = {
};

const AttachmentFields = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="col-sm-12">
      <h4>Attachments</h4>

      <div className="col-sm-12">
        <Field
          name="profile['data']['pitch_deck']"
          type="text"
          component={FileUpload}
          label="Upload your pitch deck"
          text="Drop or upload your import files here. Only image file types will be accepted"
          multiple
          accept="image/*, application/pdf"
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['pitch_deck_url']"
          type="text"
          component={TextField}
          label="If the Upload file is not working- Please upload your Pitch Deck to Google Drive or Drop Box and paste the link here"
        />
      </div>

      <div className="col-sm-12">
        <Field
          name="profile['data']['demo_video']"
          type="text"
          label="Paste Link to any Demo/Company Video (Recommended)"
          component={TextField}
        />
      </div>

      <div className="col-sm-12">
        <button type="button" className="btn btn-primary pull-left" onClick={previousPage}>
          Previous
        </button>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </div>
  </form>
);

AttachmentFields.propTypes = propTypes;
AttachmentFields.defaultProps = defaultProps;

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(AttachmentFields);
