import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import HeroStyles from '../hero/hero_styles';

const HomeStyles = styled.div`
  .huge {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    .logo {
      margin-top: 30px;
      width: 40px;
      ${breakpoint('sm')`
        width: 52px;
      `}
      ${breakpoint('md')`
        width: 66px;
      `}
    }
    .wordmark {
      margin-bottom: 30px;
    }
    h1 {
      text-align: center;
      font-size: 56px;
      letter-spacing: -0.02em;
      font-weight: 500;
      line-height: 0.9em;
      text-transform: uppercase;
      margin: 10px 0;
      color: ${(props) => props.theme.color.secondary};
      ${breakpoint('sm')`
        font-size: 80px;
        margin: 20px 0;
      `}
      ${breakpoint('md')`
        font-size: 120px;
        margin: 30px 0;
        line-height: 0.9em;
      `}
    }
  }
  ${HeroStyles} {
    height: 100vh;
    margin-top: -90px;
  }
`;

export default HomeStyles;
