import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const heights = {
  sm: {
    height: '300px',
    padding: '40px',
  },
  md: {
    height: '400px',
    padding: '60px',
  },
  lg: {
    height: '500px',
    padding: '100px',
  },
};

const TestimonialsStyles = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  height: ${heights.sm.height};
  ${breakpoint('md')`
    height: ${heights.md.height};
  `}
  ${breakpoint('lg')`
    height: ${heights.lg.height};
  `}
  position: relative;

  .container,
  .carousel,
  .carousel__slider--horizontal
  {
    height: 100%;
    box-sizing: border-box;
  }

  .carousel__dot-group {
    position: absolute;
    bottom: 20px;
  }

  .testimonial {
    padding-top: ${heights.sm.padding};
    position: relative;
    color: ${(props) => props.theme.color.light};
    ${breakpoint('md')`
      padding-top: ${heights.md.padding};
    `}
    ${breakpoint('lg')`
      padding-top: ${heights.lg.padding};
    `}
  }

  blockquote {
    font-size: ${(props) => props.theme.text.fontSize * 0.65}px;
    margin: 0;
    line-height: 1.1em;
    font-style: italic;
    font-weight: bold;
    &::before {
      content: "“";
    }
    &::after {
      content: "”";
    }
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 1.1}px; 
    `}
    ${breakpoint('md')`
      font-size: ${(props) => props.theme.text.fontSize * 1.2}px; 
    `}
    ${breakpoint('lg')`
      font-size: ${(props) => props.theme.text.fontSize * 1.75}px; 
    `}
  }

  figure {
    height: 80px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    ${breakpoint('sm')`
      height: 100px;
    `}
    ${breakpoint('md')`
      height: 120px;
    `}
    .img {
      flex-shrink: 0;
      margin-right: 16px;
      width: 50px;
      height: 50px;
      border: 6px solid ${(props) => props.theme.color.light};
      border-radius: 100%;
      ${breakpoint('sm')`
        font-size: ${(props) => props.theme.text.fontSize}px; 
        width: 65px;
        height: 65px;
      `}
      ${breakpoint('md')`
        width: 120px;
        margin-right: 30px;
        height: 120px;
      `}
    }
  }

  figcaption {
    font-size: ${(props) => props.theme.text.fontSize * 0.5}px;
    strong {
      font-weight: bold;
      line-height: 120%;
      display: inline-block;
    }
    p {
      margin: 0;
      font-size: ${(props) => props.theme.text.fontSize * 0.5}px;
    }
    ${breakpoint('sm')`
      font-size: ${(props) => props.theme.text.fontSize * 0.75}px;
      p {
        font-size: ${(props) => props.theme.text.fontSize * 0.75}px;
      }
    `}
  }

  .carousel__dot-group {
    text-align: center;
    width: 100%;
  }
  .carousel__dot {
    border: 0;
    height: 20px;
    display: inline-block;
    background: transparent;
    padding: 0 8px;
    outline: none;
    > span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.color.light};
    }
  }
  .carousel__dot--selected > span {
    background-color: ${(props) => props.theme.color.light};
  }

`;

export default TestimonialsStyles;
