import { fromJS, Map } from 'immutable';
import flamelink from 'flamelink';
import firebase from './firebase';

export const fl = flamelink({
  firebaseApp: firebase,
  env: window.location.hostname === 'demo.vinettaproject.com' || window.location.hostname === 'localhost' ? 'demo' : 'production',
});

const DEFAULT_CONTENT = new Map();

// Actions
const ACTIONS = {
  UPDATE_CONTENT: 'Content:Update',
};

const updateContent = (content) => ({
  type: ACTIONS.UPDATE_CONTENT,
  content,
});

// Reducer
export const ContentReducer = (state = DEFAULT_CONTENT, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_CONTENT:
      return state.merge(fromJS(action.content));
    default:
      return state;
  }
};

// Selectors
const getContent = (state, schema) => state.get(schema);
const getContentBySlug = (state, schema, slug) => {
  const content = getContent(state, schema);
  if (!content) return undefined;
  return content.filter((c) => c.get('slug') === slug).first();
};
const getContentByID = (state, schema, id) => {
  const content = getContent(state, schema);
  if (!content) return undefined;
  return content.get(String(id));
};
const getContentByProp = (state, schema, key, value) => {
  const content = getContent(state, schema);
  if (!content) return undefined;
  return content.filter((c) => c.get(key) === value);
};

export const ContentSelectors = {
  getContent,
  getContentBySlug,
  getContentByID,
  getContentByProp,
};

// eslint-disable-next-line no-console
const logFlamelinkError = (error) => console.log('flamelink error: ', error);

const populatedFlamelink = ['impactReport'];
// Init
const Flamelink = {
  init(store) {
    try {
      ['pages', 'snippets', 'chapters', 'testimonials', 'leaders', 'events',
        'partners', 'problemHacking', 'sponsors', 'variables', 'gallery'].forEach((schema) => {
        fl.content
          .get(schema, { populate: populatedFlamelink })
          .then((records) => {
            const content = {};
            content[schema] = records;
            store.dispatch(updateContent(content));
          })
          .catch(logFlamelinkError);
      });
    } catch (error) {
      logFlamelinkError(error);
    }
  },
};

export default Flamelink;
