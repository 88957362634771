import { connect } from 'react-redux';
import PartnershipView from './partnership_view';
import { getContentBySlug } from '../../../reducer';
import { setTitle } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(dispatch, { prop })
const mapStateToProps = (state, { location: { pathname } }) => ({
  // prop assignments
  category: pathname.substr(1),
  page: getContentBySlug(state, 'partners', pathname.substr(1)),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
});

const PartnershipContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnershipView);

export default PartnershipContainer;
