import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const ProblemHackingStyles = styled.div`
  .body {
    li {
      font-size: ${(props) => props.theme.text.fontSize * 0.7}px;
    }
    .button {
      margin: 0 30px 10px 0;
    }
  }
  .cohort-applications {
    line-height: 1.2;
    > .row {
      padding: 40px 0;
      ${breakpoint('sm')`
        padding: 80px 0;
      `}
      > div {
        padding-bottom: 60px;
      }
    }
    .img {
      padding-top: 100%;
      margin: 1em 0;
    }
  }
  .cohort-role .button > div {
    background-color: ${(props) => props.theme.color.secondary};
    border: #fff;
    color: #fff;
    font-size: 0.7em;
    margin-top: 10px;
  }
  .cohort-disclaimer {
    font-size: 0.7em;
    margin-top: 15px;
  }
`;

export default ProblemHackingStyles;
