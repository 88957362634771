import styled from 'styled-components';

const BootcampStyles = styled.div`
  .bootstrap-apply-subheader {
    padding-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;

    span {
      color: ${(props) => props.theme.color.primary};
    }
}
`;

export default BootcampStyles;
