import React from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
};

const defaultProps = {
  title: null,
  description: null,
  keywords: null,
  image: null,
};

const MetaHead = ({ title, description, keywords, image }) => {
  const mtitle = `The Vinetta Project${title ? ` / ${title}` : ''}`;
  const desc = description || 'An ecosystem and deal flow pipeline sourcing and supporting the world\'s leading female founders.';
  const img = image || 'https://firebasestorage.googleapis.com/v0/b/vinetta-project.appspot.com/o/flamelink%2Fmedia%2F1551631981280_facebook-og.jpg?alt=media';
  return (
    <MetaTags>
      <title>{mtitle}</title>
      <meta property="title" content={mtitle} />
      <meta property="description" content={desc} />
      <meta property="keywords" content={keywords} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={mtitle} />
      <meta property="og:description" content={desc} />
      {/* <meta property="og:url" content="%PUBLIC_URL%" /> */}
      {/* eslint-disable-next-line max-len */}
      <meta property="og:image" content={img} />

      {/* Open Graph Tags - Twitter Specific */}
      <meta name="twitter:title" content={mtitle} />
      <meta name="twitter:description" content={desc} />
      {/* eslint-disable-next-line max-len */}
      <meta name="twitter:image" content={img} />
      <meta name="twitter:card" content="summary_large_image" />

    </MetaTags>
  );
};

MetaHead.propTypes = propTypes;
MetaHead.defaultProps = defaultProps;

export const PageMetaHead = ({ page }) => <MetaHead title={page.get('title')} description={page.get('metaDescription')} keywords={page.get('metaKeywords')} />;

PageMetaHead.propTypes = {
  page: PropTypes.shape({}).isRequired,
};

export default MetaHead;
