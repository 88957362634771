import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import EventMapStyles from './event_map_styles';

const propTypes = {
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
};

const EventMap = ({ lat, lng }) => (
  <EventMapStyles>
    <GoogleMap defaultZoom={17} defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}>
      <Marker position={{ lat: parseFloat(lat), lng: parseFloat(lng) }} icon="/images/map-marker-primary.png" />
    </GoogleMap>
  </EventMapStyles>
);

EventMap.propTypes = propTypes;

const EventMapView = withScriptjs(withGoogleMap(EventMap));

export default EventMapView;
