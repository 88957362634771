import styled from 'styled-components';

const GalleryStyles = styled.div`
margin: 20px 0;

.gallery-image-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  .overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    left: 0;
    background-color: rgba(255, 112, 98, 0.9);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover .overlay {
    opacity: 1;
  }
  .overlay-inner {
    padding: 0 20px;

    .gallery-image-location {
      text-align: center;
      font-size: 13px;
      line-height: 19px;
      color: ${(props) => props.theme.color.light};
    }

    .gallery-image-title {
      text-align: center;
      font-size: 20px;
      line-height: 22px;
      font-weight: bold;
      color: ${(props) => props.theme.color.light};
    }

    .gallery-image-date {
      text-align: center;
      font-size: 11px;
      line-height: 17px;
      margin-bottom: 10px;
      color: ${(props) => props.theme.color.light};
    }

    a {
      display: block;
      text-align: center;

      div {
        font-size: 16px;
        color: ${(props) => props.theme.color.light};
        border-color: ${(props) => props.theme.color.secondary};
  
        &:hover {
          border-color: ${(props) => props.theme.color.light};
        }
      }
  }
}
`;

export default GalleryStyles;
